import { Button, Dialog, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import clsx from 'clsx';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import AdminService from '../../../api/admin';
import { AccessTime } from '../../../assets';
import { DialogContent, DialogTitle } from '../../../components/Dialog';
import PaginationTable from '../../../components/PaginationTable';
import { CompanyApplication } from '../../../models/CompanyApplication';
import {
  selectIsChecker,
  selectIsMaker,
  selectStaffInfo,
} from '../../auth/duck/selector';
import { selectLang } from '../../settings/duck/selector';
import RejectCompanyDialog from './RejectCompanyDialog';
import SendReminderDialog from './SendReminderDialog';

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 12,
    '& .react-table .td': {
      minHeight: 34,
      lineHeight: 'unset',
      height: 'unset',
      whiteSpace: 'break-spaces',
      display: 'flex',
      alignItems: 'center',
    },
  },
  titleIcon: {
    '& g': {
      '& g': {
        '& path': {
          fill: '#000 !important',
        },
      },
    },
  },
  buttonAccept: {
    backgroundColor: '#0909B7',
    color: '#fff',
    fontWeight: 600,
    textTransform: 'none',
  },
  buttonReject: {
    backgroundColor: '#fff',
    color: '#0909B7',
    borderColor: '#0909B7',
    fontWeight: 600,
    textTransform: 'none',
  },
  errorMessage: {
    fontSize: '1.4rem',
    lineHeight: 1.43,
    textAlign: 'center',
    color: '#707070',
    whiteSpace: 'pre-line',
    margin: '24px 0',
  },
}));

interface Props {
  setSelectedApprovals: (data: any[]) => void;
  selecting: boolean;
}
const TabPendingCompanyApproval: React.FC<Props> = ({
  setSelectedApprovals,
  selecting,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('manage_applications');
  const history = useHistory();

  const [data, setData] = React.useState<CompanyApplication[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const lang = useSelector(selectLang);
  const [error, setError] = React.useState('');

  const isChecker = useSelector(selectIsChecker);
  const isMaker = useSelector(selectIsMaker);
  const user = useSelector(selectStaffInfo);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, query, sortBy }) => {
      const fetchId = ++fetchIdRef.current;
      setLoading(true);
      AdminService.getPendingCompanyApprovals(
        pageSize,
        pageIndex,
        query,
        sortBy,
      ).then((res) => {
        if (fetchId === fetchIdRef.current) {
          setData(res.data);
          setPageCount(res.totalPage);
          setLoading(false);
        }
      });
    },
    [],
  );

  const COLUMNS = React.useMemo<Array<Column<CompanyApplication>>>(
    () => [
      {
        Header: 'manage_user:rom_stock_codes',
        accessor: 'insCode',
        disableFilters: true,
        maxWidth: 200,
      },
      {
        Header: 'manage_user:hk_ticker',
        accessor: 'tickerNo',
        disableFilters: true,
        maxWidth: 200,
      },
      {
        Header: 'entity_management:company_name',
        accessor: 'companyName',
        disableFilters: true,
        minWidth: 600,
      },
      {
        Header: 'tabbar:entity_management',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        accessor: 'entityManagement',
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'tabbar:register_of_members',
        maxWidth: 100,
        accessor: 'registerOfMember',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'tabbar:ccass_holding',
        accessor: 'ccassHolding',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'tabbar:reports',
        accessor: 'reports',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'tabbar:shareholder_analytics',
        accessor: 'ownershipAnalysis',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'tabbar:market_intelligence',
        accessor: 'marketIntelligence',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'tabbar:industry_analyst_report',
        accessor: 'industryAnalysis',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'tabbar:eagm',
        accessor: 'eAGM',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'tabbar:board_portal',
        accessor: 'boardPortal',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },

      {
        Header: 'tabbar:invoice_management',
        accessor: 'invoiceManagement',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'tabbar:thought_leadership',
        accessor: 'thoughtLeadership',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'manage_company_access:maker_checker_function',
        accessor: 'makerCheckerFunc',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'manage_company_access:acc_number_limitation',
        accessor: 'acctNoLimitation',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'manage_applications:date',
        accessor: 'requestedAt',
        disableFilters: true,
        maxWidth: 100,
        Cell: ({ value }: any) => format(new Date(value), 'yyyy-MM-dd'),
      },
      {
        Header: 'manage_applications:requested_by',
        accessor: 'requestedBy',
        disableFilters: true,
        minWidth: 320,
      },
    ],
    [t],
  );

  const onClickRow = (item: CompanyApplication) => {
    if (item.id) {
      history.push(`/admin/manage_company_application/${item.id}`);
    }
  };

  const renderActions = React.useCallback(
    (item: CompanyApplication, selected?: boolean) => {
      if (isMaker) {
        return (
          <div className="table-row-actions" style={{ visibility: 'visible' }}>
            <SendReminderDialog
              isCompanyApplication
              disabled={selecting}
              applicationIds={[item.id]}
              onSent={() => {
                setCount((state) => state + 1);
              }}
              onSendError={(e) => {
                let errorMsgKey = 'message';
                if (lang === 'zh') errorMsgKey = 'messageZH';
                else if (lang === 'cn') errorMsgKey = 'messageCN';
                setError(e.response.data[errorMsgKey]);
              }}
            />
            {/* <DeleteDialog
              disabled={selecting}
              applicationIds={[item.id]}
              onDeleted={() => {
                setCount((state) => state + 1);
              }}
              onDeleteError={(e) => {
                let errorMsgKey = 'message';
                if (lang === 'zh') errorMsgKey = 'messageZH';
                else if (lang === 'cn') errorMsgKey = 'messageCN';
                setError(e.response.data[errorMsgKey]);
              }}
            /> */}
          </div>
        );
      }
      if (!(isChecker || user?.role === 'TricorSuperAdmin')) return null;

      return (
        <div className="table-row-actions" style={{ visibility: 'visible' }}>
          <Button
            disabled={selecting}
            variant="contained"
            classes={{
              contained: classes.buttonAccept,
            }}
            onClick={(e) => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              AdminService.approveCompanyApplications([item.id])
                .then(() => {
                  setCount((state) => state + 1);
                })
                .catch((e) => {
                  let errorMsgKey = 'message';
                  if (lang === 'zh') errorMsgKey = 'messageZH';
                  else if (lang === 'cn') errorMsgKey = 'messageCN';
                  setError(e.response.data[errorMsgKey]);
                });
            }}
          >
            {t('button_approve')}
          </Button>
          <RejectCompanyDialog
            disabled={selecting}
            applicationIds={[item.id]}
            onRejected={() => {
              setCount((state) => state + 1);
            }}
            onRejectError={(e) => {
              let errorMsgKey = 'message';
              if (lang === 'zh') errorMsgKey = 'messageZH';
              else if (lang === 'cn') errorMsgKey = 'messageCN';
              setError(e.response.data[errorMsgKey]);
            }}
          />
        </div>
      );
    },
    [selecting, isChecker, user?.role],
  );

  return (
    <div className={classes.container}>
      <PaginationTable
        key={count}
        defaultPageSize={10}
        columns={COLUMNS}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        searchPlaceholder="Search by company name"
        placeholder="No company requests"
        onClickRow={onClickRow}
        renderActions={renderActions}
        renderHeaderLeft={
          <>
            <AccessTime className={clsx('section-icon', classes.titleIcon)} />
            <span className="section-title">
              {t('manage_applications:pending_for_approval')}
            </span>
          </>
        }
        allowSelection
        onSelectedFlatRowsChange={(rows) => {
          setSelectedApprovals(rows);
        }}
      />
      <Dialog
        onClose={() => setError('')}
        aria-labelledby="error-dialog-title"
        open={Boolean(error)}
      >
        <DialogTitle id="error-dialog-title" onClose={() => setError('')}>
          Error
        </DialogTitle>
        <DialogContent>
          <div className={classes.errorMessage}>{error}</div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TabPendingCompanyApproval;
