/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-wrap-multilines */
import {
  List,
  ListItem,
  ListItemText,
  TextField,
  Theme,
  makeStyles,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Pagination from '@material-ui/lab/Pagination';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import EntityService from '../../../api/entity';
import { DialogContent, DialogTitle } from '../../../components/Dialog';
import { CompanyUser } from '../../../models/CompanyUser';
import { EntityResponse } from '../../../models/EntityResponse';
import { AppDispatch, RootState } from '../../../store';
import { selectUserInfo } from '../../auth/duck/selector';
import {
  setEntities as ActionSetEntities,
  setEntity as ActionSetEntity,
} from './duck/entityDuck';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    minHeight: 480,
    minWidth: '60%',
    [theme.breakpoints.down('sm')]: {
      minWidth: '90%',
    },
  },
  content: {
    padding: 0,
  },
  list: {},
  primaryText: {
    fontSize: '1.4rem',
    color: '#000',
  },
  secondaryText: {
    fontSize: '1.2rem',
    color: '#707070',
  },
  buttonSelect: {
    height: 33,
    borderRadius: 24,
    minWidth: 128,
    marginLeft: 4,
    backgroundColor: '#0909B7',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    padding: '0 16px',
    color: '#fff',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#001A5B',
      opacity: 0.8,
    },
  },
  pagination: {
    margin: '12px 0',
    alignSelf: 'center',
    '& .MuiPaginationItem-root': {
      fontSize: '1rem',
      fontWeight: 'bold',
      borderRadius: 4,
      '&.Mui-selected': {
        backgroundColor: '#262626',
        color: '#fff',
      },
    },
  },
}));

interface Props {
  user: CompanyUser | null;

  setEntity: (entityId: string) => void;

  setEntities: (entities: EntityResponse[]) => void;
}

const SelectEntityDialog: React.FC<Props> = ({
  user,
  setEntity,
  setEntities,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('entity_management');
  const [data, setData] = React.useState<EntityResponse[]>([]);
  const [open, setOpen] = React.useState(false);

  const [pageCount, setPageCount] = React.useState(0);
  const [pageIndex, setPageIndex] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [query, setQuery] = React.useState('');
  const fetchIdRef = React.useRef(0);

  React.useEffect(() => {
    if (open && user) {
      const fetchId = ++fetchIdRef.current;
      setLoading(true);
      EntityService.getUserEntities(user.id, 10, pageIndex, query)
        .then((res) => {
          if (fetchId === fetchIdRef.current) {
            setEntities(res.data.data);
            setData(res.data.data);
            setPageCount(res.data.totalPage);
            setLoading(false);
          }
        })
        .catch(() => {
          setEntities([]);
          setPageCount(0);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [open, user, query, pageIndex]);

  React.useEffect(() => {
    setPageIndex(1);
  }, [query]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        className={classes.buttonSelect}
        onClick={handleClickOpen}
      >
        {t('select_company')}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="select-entity-dialog-title"
        open={open}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle id="select-entity-dialog-title" onClose={handleClose}>
          {t('select_company')}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <TextField
            placeholder={t('search_company_placeholder')}
            className="search-input"
            onChange={(event) => setQuery(event.target.value)}
          />
          <List className={classes.list}>
            {data.map((entity) => (
              <ListItem
                button
                key={entity.fileCode}
                onClick={() => {
                  setEntity(entity.fileCode);
                  handleClose();
                }}
              >
                <ListItemText
                  primary={`${entity.name}`}
                  classes={{
                    primary: classes.primaryText,
                    secondary: classes.secondaryText,
                  }}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <Pagination
          count={pageCount}
          defaultPage={1}
          page={pageIndex}
          showFirstButton
          showLastButton
          onChange={(e, value) => setPageIndex(value)}
          size="small"
          className={classes.pagination}
        />
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUserInfo(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setEntity: (entityId: string) => dispatch(ActionSetEntity({ entityId })),

  setEntities: (entities: EntityResponse[]) =>
    dispatch(ActionSetEntities({ entities })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectEntityDialog);
