/* eslint-disable react/jsx-props-no-spreading */
import { makeStyles, MenuItem, Theme } from '@material-ui/core';
import { Field, Form } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AdminService from '../../../api/admin';

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 24,
    '& .MuiFormControlLabel-label': {
      fontSize: '1.2rem',
      color: '#838383',
    },
  },
  label: {
    color: '#838383',
    fontSize: '1.3rem',
    fontWeight: 'bold',
    marginTop: 20,
  },
  required: {
    color: '#000',
    fontSize: '1.3rem',
  },
}));

const NumberInput = (props: any) => (
  <TextField
    {...props}
    onChange={(e) => {
      props.form.setFieldValue(
        props.field.name,
        e.target.value.replace(/[^0-9]/g, ''),
      );
    }}
  />
);

const AccessRightForm: React.FC<{
  accessRightOnly?: boolean;
  isUser?: boolean;
  hideAccessRight?: boolean;
  setFieldValue?: any;
  role?: string;
  showRequired?: boolean;
}> = ({
  accessRightOnly,
  isUser,
  setFieldValue,
  hideAccessRight,
  role,
  showRequired,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [areaCodes, setAreaCodes] = React.useState([]);

  React.useEffect(() => {
    AdminService.getAreaCodes().then((data) => {
      setAreaCodes(data);
    });
  }, []);

  return (
    <Form>
      <Field
        fullWidth
        component={TextField}
        name="name"
        label={t('manage_company:name') + (showRequired ? '*' : '')}
        inputProps={{ disabled: accessRightOnly }}
      />
      <Field
        fullWidth
        component={TextField}
        name="email"
        label={t('manage_company:email') + (showRequired ? '*' : '')}
        inputProps={{ disabled: accessRightOnly }}
      />
      <div className="row">
        <Field
          style={{ minWidth: 160 }}
          component={TextField}
          type="text"
          name="areaCode"
          label={t('manage_company:area_code')}
          select
          variant="standard"
          InputLabelProps={{ shrink: true }}
        >
          {areaCodes.map((code) => (
            <MenuItem
              key={`area-code::${code}`}
              value={code}
              style={{ height: 27 }}
            >
              {code}
            </MenuItem>
          ))}
        </Field>
        <div style={{ width: 8 }} />
        <Field
          fullWidth
          component={NumberInput}
          name="phoneNo"
          label={t('manage_company:phone_number')}
          inputProps={{ maxlength: 50, type: 'tel' }}
        />
      </div>
      <Field
        fullWidth
        component={TextField}
        type="text"
        name="role"
        label={t('manage_staff:select_user_type') + (showRequired ? '*' : '')}
        select
        variant="standard"
        InputLabelProps={{ shrink: true }}
      >
        {(isUser
          ? [
              { label: '', value: '' },
              { label: 'Client User', value: 'ClientUser' },
              { label: 'Client Checker', value: 'ClientChecker' },
              { label: 'Client Maker', value: 'ClientMaker' },
            ]
          : [
              { label: '', value: '' },
              { label: 'Super Admin', value: 'TricorSuperAdmin' },
              { label: 'Staff Checker', value: 'TricorChecker' },
              { label: 'Staff Maker', value: 'TricorMaker' },
            ].filter(
              (item) =>
                !(item.value === 'TricorSuperAdmin' && role === 'TricorMaker'),
            )
        ).map((item) => (
          <MenuItem
            key={`role::${item.value}`}
            value={item.value}
            style={{ height: 27 }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Field>
      {!isUser && (
        <Field
          fullWidth
          component={TextField}
          type="text"
          name="dept"
          label={t('manage_staff:department') + (showRequired ? '*' : '')}
          select
          variant="standard"
          InputLabelProps={{ shrink: true }}
        >
          {[
            { label: 'IS', value: 'IS' },
            { label: 'CS', value: 'CS' },
          ].map((item) => (
            <MenuItem
              key={`role::${item.value}`}
              value={item.value}
              style={{ height: 27 }}
            >
              {item.label}
            </MenuItem>
          ))}
        </Field>
      )}
      {!hideAccessRight && (
        <>
          <div className={classes.label}>
            {t('manage_company:access_rights_list')}
          </div>
          <div className="row">
            <div className={classes.column}>
              <Field
                component={CheckboxWithLabel}
                name="entityManagement"
                Label={{ label: t('tabbar:entity_management') }}
                type="checkbox"
              />
              <Field
                component={CheckboxWithLabel}
                name="registerOfMember"
                Label={{ label: t('tabbar:register_of_members') }}
                type="checkbox"
              />
              <Field
                component={CheckboxWithLabel}
                name="ccassHolding"
                Label={{ label: t('tabbar:ccass_holding') }}
                type="checkbox"
              />
              <Field
                component={CheckboxWithLabel}
                name="reports"
                Label={{ label: t('tabbar:reports') }}
                type="checkbox"
              />
              <Field
                component={CheckboxWithLabel}
                name="ownershipAnalysis"
                Label={{ label: t('tabbar:shareholder_analytics') }}
                type="checkbox"
              />
              <Field
                component={CheckboxWithLabel}
                name="marketIntelligence"
                Label={{ label: t('tabbar:market_intelligence') }}
                type="checkbox"
              />
              <Field
                component={CheckboxWithLabel}
                name="industryAnalysis"
                Label={{ label: t('tabbar:industry_analyst_report') }}
                type="checkbox"
              />
            </div>
            <div className={classes.column}>
              <Field
                component={CheckboxWithLabel}
                name="eAGM"
                Label={{ label: t('tabbar:eagm') }}
                type="checkbox"
              />
              {/* <Field
            component={CheckboxWithLabel}
            name="eProxy"
            Label={{ label: t('tabbar:eproxy') }}
            type="checkbox"
          /> */}
              <Field
                component={CheckboxWithLabel}
                name="boardPortal"
                Label={{ label: t('tabbar:board_portal') }}
                type="checkbox"
              />
              <Field
                component={CheckboxWithLabel}
                name="invoiceManagement"
                Label={{ label: t('tabbar:invoice_management') }}
                type="checkbox"
              />
              <Field
                component={CheckboxWithLabel}
                name="thoughtLeadership"
                Label={{ label: t('tabbar:thought_leadership') }}
                type="checkbox"
              />
            </div>
          </div>
          {showRequired && (
            <div className={classes.required}>{t('common:required')}</div>
          )}
        </>
      )}
    </Form>
  );
};

export default AccessRightForm;
