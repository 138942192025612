/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
  Container,
  Grid,
  Paper,
  Theme,
  createStyles,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import AuthService from '../../../api/auth';
import {
  boardfoliloStepsVertical,
  boardfolio,
  boardfolio1,
  boardfolio2,
  boardfolio3,
  boardfolio4,
  boardfolioPlaceholder,
  boardfolioSteps,
} from '../../../assets';
import HeaderGroup from '../../../components/HeaderGroup';
import LearnMoreDialog from '../../../components/LearnMoreDialog';
import { CompanyUser } from '../../../models/CompanyUser';
import { RootState } from '../../../store';
import { selectUserInfo } from '../../auth/duck/selector';
import { selectLang } from '../../settings/duck/selector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'rgba(245, 245, 245, 0.5)',
      whiteSpace: 'break-spaces',
      '& ul': {
        paddingInlineStart: '12px',
      },
      '& li': {
        marginTop: 12,
      },
    },
    headerContainer: {
      backgroundColor: '#fff',
      padding: '0 16px',
    },
    contentContainer: {
      padding: '28px 32px',
      fontSize: '1.2rem',
      color: '#262626',
    },
    centerGrid: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    iconBoardfolio: {
      width: 200,
      marginBottom: 8,
    },
    placeholderImage: {
      width: '80%',
      maxHeight: 300,
      objectFit: 'contain',
    },
    title: {
      fontSize: '1.6rem',
      fontWeight: 'bold',
      margin: '20px 0',
    },
    subtitle: {
      fontWeight: 'bold',
      marginTop: 12,
    },
    steps: {
      width: 'calc(100% + 64px)',
      objectFit: 'contain',
      marginLeft: -32,
    },
    stepsVertical: {
      width: '100%',
      objectFit: 'contain',
    },
    card: {
      borderRadius: 4,
      boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
      backgroundColor: '#ffffff',
      padding: 28,
    },

    itemTitle: {
      display: 'inline-block',
      fontSize: '1.4rem',
      fontWeight: 'bold',
    },
    itemIcon: {
      height: 18,
      width: 18,
      marginRight: 8,
    },
    button: {
      width: 'fit-content',
      marginTop: 12,
      backgroundColor: '#0909B7',
      '&:hover': {
        backgroundColor: '#001A5B',
        opacity: 0.8,
      },
      '& .MuiButton-endIcon': {
        marginLeft: 4,
      },
    },
    buttonText: {
      textTransform: 'capitalize',
      fontSize: '1.6rem',
      fontWeight: 'bold',
      color: ' #fff',
    },
  }),
);

interface Props {
  user?: CompanyUser | null;
}

export const Boardfolio: React.FC<Props> = ({ user }) => {
  const classes = useStyles();
  const matched = useMediaQuery('(min-width:920px)');
  const { t } = useTranslation('boardfolio');
  const lang = useSelector(selectLang);
  const [open, setOpen] = React.useState(false);

  const onClickLearnMore = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    if (!user) return;

    const link = document.createElement('a');
    link.href = '/files/Boardfolio_leaflet_2009.pdf';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    AuthService.learnMore(
      user.id,
      user.accessRights.registerOfMember ? 'Boardfolio' : 'CSCompany',
    ).then((res) => {
      setOpen(true);
    });
  };

  return (
    <div className={classes.root}>
      <LearnMoreDialog
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
        message={t('common:learn_more_message', {
          feature: t('common:learn_more_boardfolio'),
        })}
      />
      <Grid container spacing={3} className={classes.headerContainer}>
        <Grid item xs={12} md={6} lg={7} />
        <Grid item xs={12} md={6} lg={5}>
          <HeaderGroup />
        </Grid>
      </Grid>
      <Container
        maxWidth="xl"
        disableGutters
        className={classes.contentContainer}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <img
              src={boardfolio}
              alt="boardfolio"
              className={classes.iconBoardfolio}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={7}>
            <div>{t('boardfolio_intro')}</div>
            <div className={classes.title}>{t('what_is_boardfolio')}</div>
            <div>{t('what_is_boardfolio_description')}</div>
            <div className={classes.subtitle}>
              {t('advanced_corporate_governance')}
            </div>
            <div>{t('advanced_corporate_governance_description')}</div>
            <div className={classes.subtitle}>
              {t('seamless_meeting_management')}
            </div>
            <div>{t('seamless_meeting_management_description')}</div>
          </Grid>
          <Grid item xs={12} md={6} lg={5} className={classes.centerGrid}>
            <img
              src={boardfolioPlaceholder}
              className={classes.placeholderImage}
            />
          </Grid>
          <Grid item xs={12}>
            <img
              src={matched ? boardfolioSteps[lang] : boardfoliloStepsVertical}
              className={matched ? classes.steps : classes.stepsVertical}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.title}>{t('what_are_the_benfits')}</div>
        </Grid>
        <Grid container item direction="row" spacing={2} alignItems="stretch">
          {[
            {
              icon: boardfolio1,
              title: t('security_and_access_control'),
              data: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: t('security_and_access_control_description'),
                  }}
                />
              ),
            },
            {
              icon: boardfolio2,
              title: t('seamless_collaboration'),
              data: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: t('seamless_collaboration_description'),
                  }}
                />
              ),
            },
            {
              icon: boardfolio3,
              title: t('enhanced_productivity_and_efficiency'),
              data: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'enhanced_productivity_and_efficiency_description',
                    ),
                  }}
                />
              ),
            },
            {
              icon: boardfolio4,
              title: t('adminstrative_ease'),
              data: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: t('adminstrative_ease_description'),
                  }}
                />
              ),
            },
          ].map(({ title, data, icon }) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              key={title}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <Paper square className={classes.card} style={{ flex: 1 }}>
                <div className="row">
                  <img src={icon} className={classes.itemIcon} />
                  <div className={classes.itemTitle}>{title}</div>
                </div>
                {data}
              </Paper>
            </Grid>
          ))}
          <Grid item xs={12}>
            <div className={classes.title}>{t('how_can_tricor_help')}</div>
            <div>{t('how_can_tricor_help_description')}</div>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              disableFocusRipple
              disableRipple
              classes={{
                root: classes.button,
                label: classes.buttonText,
              }}
              onClick={onClickLearnMore}
            >
              {t('common:learn_more')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUserInfo(state),
});

export default connect(mapStateToProps)(Boardfolio);
