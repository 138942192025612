/* eslint-disable no-nested-ternary */
import {
  Button,
  Card,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { format } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import cnLocale from 'date-fns/locale/zh-CN';
import zhLocale from 'date-fns/locale/zh-TW';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AuthService from '../../../api/auth';
import RomService from '../../../api/rom';
import ThoughtLeadershipService from '../../../api/thoughtLeadership';
import {
  IssueSummaryIcon,
  ccassHolding,
  csCorporateActions,
  csDividendPayment,
  csRegistryManagement,
  csShareholderCommunications,
  dollarOrange,
  dollarPurple,
  dots,
  peopleBlue,
  quotation,
} from '../../../assets';
import HeaderGroup from '../../../components/HeaderGroup';
import LearnMoreDialog from '../../../components/LearnMoreDialog';
import { Article } from '../../../models/Article';
import { CompanyInstrument } from '../../../models/CompanyInstrument';
import { ListedCompany } from '../../../models/ListedCompany';
import { selectUserInfo } from '../../auth/duck/selector';
import { selectLang } from '../../settings/duck/selector';
import SelectCompanyDialog from '../registerOfMembers/SelectCompanyDialog';
import Calendar from './calendar/Calendar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'rgba(245, 245, 245, 0.5)',
    },
    headerContainer: {
      backgroundColor: '#fff',
      padding: '0 16px',
    },
    contentContainer: {
      padding: '28px 32px',
    },
    company: {
      verticalAlign: 'middle',
      fontSize: '1.6rem',
      fontWeight: 'bold',
      color: '#262626',
      marginRight: 8,
      marginBottom: 8,
    },
    separator: {
      border: 'solid 1px #d9d9d9',
    },
    infoCard: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      height: 400,
      background: '#fff',
      cursor: 'pointer',
    },
    cardBg: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      objectFit: 'contain',
    },
    cardText: {
      marginRight: 8,
      fontSize: '1.8rem',
      fontWeight: 'bold',
    },
    row: {
      margin: 16,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
      zIndex: 100,
    },
    borderButton: {
      minWidth: 100,
      border: 'solid 1px #e84637',
      borderRadius: 17,
      backgroundColor: '#fff',
      height: 33,
      color: '#e84637',
      fontWeight: 'bold',
      fontSize: '1.2rem',
      textTransform: 'none',
    },
    summaryItem: {
      flex: 1,
      padding: 16,
      overflow: 'auto',
    },
    summaryItemIcon: {
      height: 24,
      width: 24,
    },
    label: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      fontSize: '1.4rem',
      color: '#707070',
      whiteSpace: 'break-spaces',
    },
    value: {
      fontSize: '1.8rem',
      fontWeight: 'bold',
      color: '#262626',
    },
    buttonAddEvent: {
      background: '#707070',
      padding: 4,
      '&:hover': {
        backgroundColor: '#707070',
        opacity: 0.8,
      },
    },
    articleAvatar: {
      height: 50,
      width: 50,
      borderRadius: 4,
    },
    articleText: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
    },
    quoteContainer: {
      minHeight: 200,
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 20,
    },
    quoteCompany: {
      alignSelf: 'flex-start',
      fontSize: '1.4rem',
      fontWeight: 'bold',
      marginBottom: 10,
    },
    quoteCurrency: {
      fontSize: 20,
      fontWeight: 'bold',
      color: '#262626',
      marginTop: 8,
      marginRight: 12,
    },
    quotePrice: {
      fontWeight: 'bold',
      fontSize: '5rem',
      color: '#262626',
    },
    quoteChange: {
      fontSize: '2rem',
      fontWeight: 'bold',
      margin: 12,
      '& .MuiSvgIcon-root': {
        verticalAlign: 'middle',
      },
    },
    quoteLastUpdated: {
      fontSize: '1.2rem',
      color: '#262626',
    },
    delayedQuote: {
      fontSize: '1.2rem',
      color: '#b4b4b4',
      textAlign: 'end',
    },
    buttonLearnMore: {
      width: 'fit-content',
      borderRadius: 40,
      backgroundColor: '#0909B7',
      '&:hover': {
        backgroundColor: '#001A5B',
        opacity: 0.8,
      },
      '& .MuiButton-endIcon': {
        marginLeft: 4,
      },
    },
    buttonTextLearnMore: {
      textTransform: 'capitalize',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      color: ' #fff',
    },
  }),
);

interface StockQuote extends CompanyInstrument {
  regularMarketChange: number;
  regularMarketChangePercent: number;
  regularMarketPrice: number;
  symbol: string;
  timestamp: string;
}

interface Props {
  company?: ListedCompany;

  setCompany: (company: ListedCompany) => void;

  canSelectCompany: boolean;

  setCanSelectCompany: (enable: boolean) => void;
}

const Home: React.FC<Props> = ({
  setCompany,
  company,
  canSelectCompany,
  setCanSelectCompany,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation('home');
  const lang = useSelector(selectLang);
  const [articles, setArticles] = React.useState<Article[]>([]);
  const [stockQuote, setStockQuote] = React.useState<StockQuote>();
  const [message, setMessage] = React.useState<string>();
  const user = useSelector(selectUserInfo);

  const instrument = company?.instruments?.[0];

  React.useEffect(() => {
    if (!company && user) {
      RomService.getUserRom(user.id, 1, 1).then((res) => {
        setCompany(res.data.data[0]);
        setCanSelectCompany(res.data.totalPage > 1);
      });
    }
  }, [user]);

  React.useEffect(() => {
    ThoughtLeadershipService.getThoughtLeadership(5, 1).then((res) => {
      setArticles(res.data);
    });
  }, []);

  React.useEffect(() => {
    if (company?.instruments?.[0]) {
      const { insOid, insCode } = company.instruments[0];

      setStockQuote(undefined);
      RomService.getStockPrice(insOid, insCode, company.companyType).then(
        ({ data }) => {
          setStockQuote({ ...company.instruments[0], ...data });
        },
      );
    }
  }, [company]);

  const summaries = [
    {
      field: `${t('register_of_members:no_of_holders')}\n `,
      value: instrument?.hdrCnt?.toLocaleString() || '-',
      icon: peopleBlue,
    },
    {
      field: `${t('register_of_members:issued_capital')}\n `,
      value: instrument?.localCap?.toLocaleString() || '-',
      icon: dots,
    },
    {
      field: `${t('register_of_members:par_value')}\n `,
      value: instrument?.parValue
        ? `${instrument?.parValueCurrency?.[lang]} ${parseFloat(
            instrument?.parValue.toString(),
          ).toLocaleString(undefined, {
            maximumFractionDigits: 20,
            minimumFractionDigits: 0,
          })}`
        : t('register_of_members:not_applicable'),
      icon: dollarOrange,
    },
    {
      field: `${t('register_of_members:trading_counter')}\n `,
      value: instrument?.tradeCurrency?.[lang] || '-',
      icon: dollarPurple,
    },
  ];

  const renderStockQuote = () => {
    if (!stockQuote) return null;
    const {
      instFullName,
      symbol,
      regularMarketPrice,
      regularMarketChange,
      regularMarketChangePercent,
      timestamp,
    } = stockQuote;
    let color = '#262626';
    let prefix = '+';
    if (regularMarketChange > 0) {
      color = '#1cbc5c';
    } else if (regularMarketChange < 0) {
      color = 'red';
      prefix = '';
    }

    return (
      <>
        <div className={classes.quoteCompany}>
          {`${
            lang === 'en'
              ? company?.cmpEngName
              : company?.cmpChiName || company?.cmpEngName
          }(${symbol})`}
        </div>
        <div>
          <div className="row" style={{ justifyContent: 'center' }}>
            <span className={classes.quoteCurrency}>
              {t('currency:hong_kong_dollars')}
            </span>
            <span className={classes.quotePrice}>{regularMarketPrice}</span>
          </div>
          <div className="row" style={{ justifyContent: 'center', color }}>
            <div className={classes.quoteChange}>
              {regularMarketChange < 0 && (
                <ArrowDropDownIcon htmlColor={color} fontSize="large" />
              )}
              {regularMarketChange > 0 && (
                <ArrowDropUpIcon htmlColor={color} fontSize="large" />
              )}
              {`${prefix}${regularMarketChange}`}
            </div>
            <div className={classes.quoteChange} style={{ color }}>
              {`${prefix}${regularMarketChangePercent.toLocaleString(
                undefined,
                {
                  maximumSignificantDigits: 3,
                  minimumSignificantDigits: 3,
                },
              )}%`}
            </div>
          </div>
        </div>
        <div className={classes.quoteLastUpdated}>
          {`${t('last_updated')} : `}
          {format(
            new Date(timestamp),
            `MMM dd'${lang === 'en' ? ',' : '日,'}' yyyy '${
              lang === 'en' ? 'at' : ' '
            }' HH:mm aaaa O`,
            {
              locale:
                lang === 'en' ? enLocale : lang === 'zh' ? zhLocale : cnLocale,
            },
          )}
          <div className={classes.delayedQuote}>
            {`- ${t('delayed_quote')}`}
          </div>
        </div>
      </>
    );
  };

  const onClickCcassHoldings = () => {
    if (user?.accessRights.ccassHolding) {
      history.push('ccass-holding');
      return;
    }

    if (!user) return;

    AuthService.learnMore(
      user.id,
      user.accessRights.registerOfMember
        ? 'CCASSHolding'
        : `CSCompany/${company?.cmpEngName}`,
    ).then((res) => {
      setMessage(
        t('common:learn_more_message', {
          feature: t('common:learn_more_ccass'),
        }),
      );
    });
  };

  const onClickShareholderAnalytics = () => {
    if (user?.accessRights.ownershipAnalysis) {
      history.push('shareholder-analytics');
    }

    if (!user) return;

    AuthService.learnMore(
      user.id,
      user.accessRights.registerOfMember
        ? 'OwnershipAnalysis'
        : `CSCompany/${company?.cmpEngName}`,
    ).then((res) => {
      setMessage(
        t('common:learn_more_message', {
          feature: t('common:learn_more_ownership_analytics'),
        }),
      );
    });
  };

  const onClickLearnMore = () => {
    if (!user) return;

    const link = document.createElement('a');
    link.href = `/files/${
      lang === 'en'
        ? 'Share_Registry_&_Issuer_Services_v2'
        : 'Vistra卓佳股份登记服务_v2'
    }.pdf`;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    AuthService.learnMore(user.id, `CSCompany/${company?.cmpEngName}`).then(
      (res) => {
        setMessage(
          t('common:learn_more_message', {
            feature: 'Share Registrar & Issuer Services',
          }),
        );
      },
    );
  };

  return (
    <div className={classes.root}>
      <LearnMoreDialog
        open={!!message}
        handleClose={() => {
          setMessage(undefined);
        }}
        message={message || ''}
      />
      <Grid container spacing={3} className={classes.headerContainer}>
        <Grid
          item
          xs={12}
          md={6}
          lg={7}
          style={{ display: 'flex', alignItems: 'flex-end' }}
        >
          <span className={classes.company}>
            {lang === 'en'
              ? company?.cmpEngName
              : company?.cmpChiName || company?.cmpEngName}
            {instrument?.insCode ? `(${instrument?.insCode})` : ''}
          </span>
          {canSelectCompany && (
            <SelectCompanyDialog
              selectCompany={(item: ListedCompany) => {
                setCompany(item);
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <HeaderGroup />
        </Grid>
      </Grid>
      <Container
        maxWidth="xl"
        disableGutters
        className={classes.contentContainer}
      >
        <Grid container spacing={2} direction="row">
          <Grid container item spacing={2} xs={8} lg={8}>
            <Grid item xs={6} style={{ display: 'flex' }} direction="column">
              <div className="section-container">
                <img
                  src={quotation}
                  className="section-icon"
                  alt="stock quotation"
                />
                <span className="section-title">{t('stock_quotation')}</span>
              </div>
              <Card className={classes.quoteContainer}>
                {renderStockQuote()}
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Container
                disableGutters
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div className="section-container">
                  <IssueSummaryIcon className="section-icon" />
                  <span className="section-title">
                    {t('register_of_members:issue_summary')}
                  </span>
                </div>
                {company?.companyType === 'CSCompany' ? (
                  <Card
                    className={classes.quoteContainer}
                    style={{ justifyContent: 'flex-start' }}
                  >
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div style={{ fontSize: '1.4rem', fontWeight: 'bold' }}>
                        {t('share_registration_and_issuer_services')}
                      </div>
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        disableFocusRipple
                        disableRipple
                        classes={{
                          root: classes.buttonLearnMore,
                          label: classes.buttonTextLearnMore,
                        }}
                        onClick={onClickLearnMore}
                      >
                        {t('common:learn_more')}
                      </Button>
                    </div>
                    <Grid container>
                      {[
                        {
                          title: 'registry_management',
                          icon: csRegistryManagement,
                        },
                        {
                          title: 'corporate_actions',
                          icon: csCorporateActions,
                        },
                        {
                          title: 'shareholder_communications',
                          icon: csShareholderCommunications,
                        },
                        { title: 'dividend_payment', icon: csDividendPayment },
                      ].map((item) => (
                        <Grid
                          item
                          xs={6}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingTop: 16,
                          }}
                        >
                          <div
                            style={{
                              width: 60,
                              height: 60,
                              borderRadius: 30,
                              boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
                              backgroundColor: '#ffffff',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <img
                              src={item.icon}
                              style={{
                                height: 40,
                                width: 40,
                                objectFit: 'contain',
                              }}
                            />
                          </div>
                          <div
                            style={{
                              fontSize: '1.2rem',
                              fontWeight: 'bold',
                              margin: '0px 10px',
                              flex: 1,
                              wordBreak: 'break-word',
                            }}
                          >
                            {t(item.title)}
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </Card>
                ) : (
                  <Grid spacing={2} container style={{ flex: 1 }}>
                    {summaries.map((item) => (
                      <Grid
                        item
                        key={item.field}
                        xs={12}
                        lg={6}
                        style={{ display: 'flex' }}
                      >
                        <Paper className={classes.summaryItem}>
                          <div className={classes.label}>
                            {item.field}
                            <img
                              src={item.icon}
                              alt={item.field}
                              className={classes.summaryItemIcon}
                            />
                          </div>
                          <div className={classes.value}>{item.value}</div>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Container>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.separator} />
            </Grid>
            <Grid item xs={6}>
              <Card className={classes.infoCard} onClick={onClickCcassHoldings}>
                <img
                  src={ccassHolding}
                  alt="ccass holding"
                  className={classes.cardBg}
                />
                <div className={classes.row}>
                  <span className={classes.cardText}>{t('ccass_holding')}</span>
                </div>
              </Card>
            </Grid>
            {/*<Grid item xs={6}>
              <Card
                className={classes.infoCard}
                onClick={onClickShareholderAnalytics}
              >
                <img
                  src={ownershipAnalytics}
                  alt="ownership analytics"
                  className={classes.cardBg}
                />
                <div className={classes.row}>
                  <span className={classes.cardText}>
                    {t('ownership_analytics')}
                  </span>
                </div>
              </Card>
                    </Grid>*/}
          </Grid>
          <Grid container item xs={4} lg={4}>
            <Container disableGutters>
              <Calendar company={company} defaultValue={new Date()} />
              <div className="section-container">
                <img
                  src={quotation}
                  className="section-icon"
                  alt="news letter"
                />
                <span className="section-title">
                  {t('tabbar:thought_leadership')}
                </span>
              </div>
              <List>
                {articles.map((article) => (
                  <ListItem
                    id={article.id.toString()}
                    role={undefined}
                    button
                    onClick={() => window.open(article.webLink, '_blank')}
                  >
                    <ListItemAvatar>
                      <img
                        alt="article"
                        src={article.imageUrl}
                        className={classes.articleAvatar}
                      />
                    </ListItemAvatar>
                    <ListItemText classes={{ primary: classes.articleText }}>
                      {article.title}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Home;
