/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
  Container,
  Grid,
  Paper,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { Column } from 'react-table';
import AuthService from '../../../api/auth';
import EagmService from '../../../api/eagm';
import {
  eagmPlaceholder,
  eproxy1,
  eproxy2,
  eproxy3,
  eproxy4,
  eproxyFeature1,
  eproxyFeature2,
  eproxyFeature3,
  eproxyFeature4,
  eproxyFeature5,
  eproxyPlaceholder,
  spot,
  spot1,
  spot2,
  spot3,
  spot4,
} from '../../../assets';
import HeaderGroup from '../../../components/HeaderGroup';
import LearnMoreDialog from '../../../components/LearnMoreDialog';
import PaginationTable from '../../../components/PaginationTable';
import { CompanyUser } from '../../../models/CompanyUser';
import { Meeting } from '../../../models/Meetings';
import { RootState } from '../../../store';
import { selectUserInfo } from '../../auth/duck/selector';
import { selectLang } from '../../settings/duck/selector';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'rgba(245, 245, 245, 0.5)',
    '& .search-bar': {
      marginLeft: -1,
      '& .MuiInputBase-root': {
        borderRadius: '0 4px 4px 0',
      },
    },
  },
  headerContainer: {
    backgroundColor: '#fff',
    padding: '0 16px',
  },
  contentContainer: {
    padding: '28px 32px',
  },
  container: {
    marginTop: 12,
    '& .react-table .tr .th:first-of-type': {
      maxWidth: 100,
    },
    '& .react-table .tr .td:first-of-type': {
      maxWidth: 100,
    },
    '& .react-table .table-row-actions': {
      visibility: 'visible',
    },
  },
  sectionTitle: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#262626',
  },
  title: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: '#262626',
  },
  content: {
    fontSize: '1.2rem',
    color: '#000',
    lineHeight: 1.17,
    margin: '20px 0',
    whiteSpace: 'pre-wrap',
  },
  select: {
    '& .MuiSelect-root': {
      borderColor: '#b4b4b4',
      borderWidth: 1,
      borderRadius: '4px 0 0 4px',
      paddingTop: 7,
    },
  },
  centerGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconBoardfolio: {
    width: 114,
    marginBottom: 8,
  },
  itemTitle: {
    display: 'inline-block',
    fontSize: '1.4rem',
    fontWeight: 'bold',
  },
  itemIcon: {
    height: 18,
    width: 18,
    marginRight: 8,
  },
  placeholderImage: {
    width: '80%',
    maxHeight: 300,
    objectFit: 'contain',
  },
  steps: {
    width: 'calc(100% + 64px)',
    objectFit: 'contain',
    marginLeft: -32,
  },
  stepsVertical: {
    width: '100%',
    objectFit: 'contain',
  },
  card: {
    borderRadius: 4,
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
    backgroundColor: '#ffffff',
    padding: 28,
    '& ul': {
      paddingLeft: 16,
    },
  },
  button: {
    height: 25,
    width: 125,
    borderRadius: 14,
    marginTop: 12,
    backgroundColor: '#0909B7',
    '&:hover': {
      backgroundColor: '#001A5B',
      opacity: 0.8,
    },
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
  },
  buttonText: {
    textTransform: 'capitalize',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: ' #fff',
  },
  iconSpot: {
    width: 100,
    margin: '12px 8px',
  },
  featureItem: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: 90,
    marginBottom: 24,
  },
  featureIcon: {
    height: 54,
    width: 54,
  },
  featureText: {
    fontSize: '1.2rem',
    margin: '0 20px',
  },
}));

interface Props {
  user?: CompanyUser | null;
}
export const EAgm: React.FC<Props> = ({ user }) => {
  const { t } = useTranslation('eagm');
  const classes = useStyles();
  const matched = useMediaQuery('(min-width:920px)');
  const [open, setOpen] = React.useState(false);

  const [hasData, setHasData] = React.useState(false);
  const [meetings, setMeetings] = React.useState<Meeting[]>([]);
  const [insCode, setInsCode] = React.useState<string>('all');
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [options, setOptions] = React.useState<string[]>([]);
  const lang = useSelector(selectLang);
  const fetchIdRef = React.useRef(0);

  const COLUMNS = React.useMemo<Array<Column<Meeting>>>(
    () => [
      {
        Header: 'eagm:stock_code',
        accessor: 'insCode',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'eagm:company',
        accessor: 'insName',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => value[lang],
      },
      {
        Header: 'eagm:meeting_type',
        accessor: 'meetingType',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => value[lang],
        width: 200,
      },
      {
        Header: 'eagm:meeting_time',
        accessor: 'meetingDateTime',
        disableFilters: true,
        width: 200,
        Cell: ({ value }: any) => format(new Date(value), 'yyyy-MM-dd HH:mm'),
      },
    ],
    [t, options],
  );

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, query, sortBy }) => {
      const fetchId = ++fetchIdRef.current;
      setLoading(true);
      EagmService.getMeetings(
        pageSize,
        pageIndex,
        query,
        sortBy,
        insCode === 'all' ? undefined : 'instrumentCode',
        insCode,
      ).then(({ data, totalPage }) => {
        if (fetchId === fetchIdRef.current) {
          setMeetings(data as any);
          setPageCount(totalPage);
          setLoading(false);
        }
      });
    },
    [insCode],
  );

  React.useEffect(() => {
    EagmService.getMeetingStockCodeOptions().then((res) => {
      setOptions(res.stockCodes);
    });

    EagmService.getMeetings(10, 1).then(({ data }) => {
      setHasData(data.length > 0);
    });

    fetchData({
      pageSize: 10,
      pageIndex: 1,
    });
  }, []);

  const onClickRow = (item: Meeting) => {
    EagmService.getMeetingUrl(item.meetingOid, item.username).then((res) => {
      const link = document.createElement('a');
      link.href = res.data.url;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const onClickLearnMore = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    if (!user) return;

    const link = document.createElement('a');
    link.href = `/files/${
      lang === 'en'
        ? 'Combined_Leaflet_for_SPOT'
        : 'Combined_Leaflet_for_SPOT_Chinese_version'
    }.pdf`;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    AuthService.learnMore(
      user.id,
      user.accessRights.registerOfMember ? 'eAGM' : 'CSCompany',
    ).then((res) => {
      setOpen(true);
    });
  };

  if (meetings.length === 0 && !hasData)
    return (
      <div className={classes.root}>
        <LearnMoreDialog
          open={open}
          handleClose={() => {
            setOpen(false);
          }}
          message={t('common:learn_more_message', {
            feature: t('common:learn_more_eagm'),
          })}
        />
        <Grid container spacing={3} className={classes.headerContainer}>
          <Grid item xs={12} md={6} lg={7} />
          <Grid item xs={12} md={6} lg={5}>
            <HeaderGroup />
          </Grid>
        </Grid>
        <Container
          maxWidth="xl"
          disableGutters
          className={classes.contentContainer}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <img
                src={spot}
                alt="boardfolio"
                className={classes.iconBoardfolio}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div className={classes.sectionTitle}>{t('hybrid_meeting')}</div>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                disableFocusRipple
                disableRipple
                classes={{
                  root: classes.button,
                  label: classes.buttonText,
                }}
                onClick={onClickLearnMore}
              >
                {t('common:learn_more')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.title}>{t('what_is_hybrid_meeting')}</div>
              <div className={classes.content}>
                {t('what_is_hybrid_meeting_description')}
              </div>
            </Grid>
            <Grid item xs={12} md={6} style={{ paddingTop: 48 }}>
              <img
                src={eagmPlaceholder}
                className={classes.placeholderImage}
                alt=""
              />
            </Grid>
            <Grid container item xs={12} md={6} spacing={2}>
              <Grid item xs={12}>
                <div className={classes.title}>
                  {t('hybrid_meeting_what_are_the_benfits')}
                </div>
              </Grid>
              {[
                {
                  icon: spot1,
                  title: t('member_engagement'),
                  data: t('member_engagement_description'),
                },
                {
                  icon: spot2,
                  title: t('real_time_collaboration'),
                  data: t('real_time_collaboration_description'),
                },
                {
                  icon: spot3,
                  title: t('transparency_and_security'),
                  data: t('transparency_and_security_description'),
                },
                {
                  icon: spot4,
                  title: t('saving_and_efficiency'),
                  data: t('saving_and_efficiency_decscription'),
                },
              ].map(({ title, data, icon }) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  key={title}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Paper square className={classes.card} style={{ flex: 1 }}>
                    <div className="row">
                      <img src={icon} className={classes.itemIcon} />
                      <div className={classes.itemTitle}>{title}</div>
                    </div>
                    <div
                      style={{ fontSize: '1.2rem' }}
                      dangerouslySetInnerHTML={{
                        __html: data,
                      }}
                    />
                  </Paper>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12}>
              <div className={classes.title}>
                {t('spot_is_ultimate_solution')}
              </div>
              <div className={classes.content}>
                {t('spot_is_ultimate_solution_description')}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div
                className={classes.sectionTitle}
                style={{ marginBottom: 16 }}
              >
                {t('eproxy_intro')}
              </div>
              <div className={classes.title}>{t('what_is_eproxy')}</div>
              <div className={classes.content}>
                {t('what_is_eproxy_description')}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.title}>{t('key_features_of_eproxy')}</div>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              {[
                { icon: eproxyFeature1, label: 'key_features_1' },
                { icon: eproxyFeature4, label: 'key_features_4' },
                { icon: eproxyFeature2, label: 'key_features_2' },
                { icon: eproxyFeature3, label: 'key_features_3' },
                { icon: eproxyFeature5, label: 'key_features_5' },
              ].map((item) => (
                <Grid item xs={12} md={4} className={classes.featureItem}>
                  <img className={classes.featureIcon} src={item.icon} alt="" />
                  <div className={classes.featureText}>{t(item.label)}</div>
                </Grid>
              ))}
              <Grid item xs={12} md={4} />
            </Grid>
            <Grid item xs={12} md={6} style={{ paddingTop: 48 }}>
              <img
                src={eproxyPlaceholder}
                className={classes.placeholderImage}
                alt=""
              />
            </Grid>
            <Grid container item xs={12} md={6} spacing={2}>
              <Grid item xs={12}>
                <div className={classes.title}>{t('what_are_the_benfits')}</div>
              </Grid>
              {[
                {
                  icon: eproxy1,
                  title: t('streamlining_management_of_proxies'),
                  data: t('streamlining_management_of_proxies_description'),
                },
                {
                  icon: eproxy2,
                  title: t('facilitating_investor_relations'),
                  data: t('facilitating_investor_relations_description'),
                },
                {
                  icon: eproxy3,
                  title: t('enhancing_corporate_governance'),
                  data: t('enhancing_corporate_governance_description'),
                },
                {
                  icon: eproxy4,
                  title: t('enjoying_security_and_efficiency'),
                  data: t('enjoying_security_and_efficiency_description'),
                },
              ].map(({ title, data, icon }) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  key={title}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Paper square className={classes.card} style={{ flex: 1 }}>
                    <div className="row">
                      <img src={icon} className={classes.itemIcon} />
                      <div className={classes.itemTitle}>{title}</div>
                    </div>
                    <div
                      style={{ fontSize: '1.2rem' }}
                      dangerouslySetInnerHTML={{
                        __html: data,
                      }}
                    />
                  </Paper>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12}>
              <div className={classes.title}>{t('how_can_tricor_help')}</div>
              <div className={classes.content}>
                {t('how_can_tricor_help_description')}
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    );

  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.headerContainer}>
        <Grid
          item
          xs={12}
          md={6}
          lg={7}
          style={{ display: 'flex', alignItems: 'flex-end' }}
        />
        <Grid item xs={12} md={6} lg={5}>
          <HeaderGroup />
        </Grid>
      </Grid>
      <Container
        maxWidth="xl"
        disableGutters
        className={classes.contentContainer}
      >
        <Grid container spacing={3}>
          <img src={spot} alt="" className={classes.iconSpot} />
          <Grid item xs={12} className={classes.title}>
            {t('welcome_to_spot')}
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: 0 }}>
            <PaginationTable
              defaultPageSize={10}
              columns={COLUMNS}
              data={meetings}
              fetchData={fetchData}
              onClickRow={onClickRow}
              loading={loading}
              pageCount={pageCount}
              searchPlaceholder={t('search_placeholder')}
              placeholder=""
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUserInfo(state),
});

export default connect(mapStateToProps)(EAgm);
