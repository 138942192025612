export default {
  common: {
    required: '*required',
    create: 'Create',
    back: 'Back',
    go_back: 'Go Back',
    select: 'Select',
    cancel: 'Cancel',
    close: 'Close',
    submit: 'Submit',
    delete: 'Delete',
    edit: 'Edit',
    ok: 'OK',
    timeout_title: 'Timeout',
    timeout_message:
      "Your Vistra service will automatically time-out in around 1 minute.\nIf you wish to continue, please click 'OK'.",
    download: 'Download',
    export: 'Export',
    done: 'Done',
    assign: 'Assign',
    unassign: 'Unassign',
    save: 'Save',
    remove: 'Remove',
    confirm: 'Confirm',
    select_fields: 'Select Fields',
    fields: 'Fields',
    all: 'All',
    from: 'From',
    to: 'To',
    learn_more: 'Learn More',
    learn_more_message:
      'Thank you for your interest in {{feature}}. Your Client Executive will contact you soon.',
    learn_more_eagm: 'SPOT',
    learn_more_eproxy: 'eProxy SPOT',
    learn_more_boardfolio: 'Boardfolio',
    learn_more_hkscc_registered_holders:
      'eCorporate Communication for Registered Holders',
    learn_more_hkscc_non_registered_holders:
      'eCorporate Communication for HKSCC Non-Registered Holders',
    learn_more_ccass: 'CCASS Holdings',
    learn_more_ownership_analytics: 'Ownership Analytics',
    learn_more_request_new_report:
      'Your request of customized report(s) is received. Your Client Executive will contact you soon. ',
    start_date_should_before_end_date:
      'Begin date should be before/same as end date',
    date_should_after_2021: 'Date should be later than 1 January 2021',
    end_date_should_after_start_date:
      'End date should be after/same as begin date',
    start_must_after_2016: 'Start date must be after 1 January 2016',
    date_must_within_a_year: 'Date must be within one year of today',
    date_cannot_be_future: 'Date cannot be a future date',
    start_must_after_minimal_date: 'Date should not be before minimal date',
    enter_any_date_preceding_current_date:
      'Enter any date preceding current date',

    date_must_before_2100: 'Date should be earlier than 1 January 2100',
    end_time_should_be_after_start_time:
      'End time should not be earlier than start time',
    please_enter_valid_date: 'Please enter valid date format (DD/MM/YYYY)',
  },
  feedback: {
    let_us_know_your_feedback:
      'Let us know about your experience so we can improve.',
    ask_feedback_title: 'We want your feedback.',
    ask_feedback_description:
      'It was our pleasure assisting you. Feedbacks help us to build a better online presence and provide a better service. Would you be willing to take a few minutes to leave us a review? Thank you so much in advance for taking time to share your experience. We appreciate your opinions.',
    leave_feedback: 'Leave Feedback',
    maybe_later: 'Maybe Later',
    do_not_show_again: 'Do not show this again.',

    name: 'Name',
    date: 'Date',
    comments: 'Comments',
    rating: 'Rating',

    give_us_feedback_title: 'Give Us Feedback',
    give_us_feedback_message:
      'Get a better experience by sharing your thoughts with us. With your feedback, we’re able to keep improving and serve you better.',
  },
  error: {
    required: 'Required',
    invalid_email: 'Invalid email address',
    required_id_or_passport_number:
      'Please enter "Hong Kong Identity Card Number" or "Passport Number"',
  },
  tabbar: {
    logout: 'Log Out',
    home: 'Home',
    register_of_members: 'Register of Members',
    ccass_holding: 'CCASS Holdings',
    reports: 'Reports',
    entity_management: 'Entity Management',
    market_intelligence: 'Market Intelligence',
    industry_analyst_report: 'Industry Analyst Report',
    shareholder_analytics: 'Ownership Analysis',
    eagm: 'SPOT',
    eproxy: 'eProxy',
    invoice_management: 'Invoice Management',
    board_portal: 'BoardFolio',
    thought_leadership: 'Vistra Inside Newsletter',
    admin_portal: 'Admin Portal',
    user_portal: 'User Portal',
    manage_company: 'Manage Company',
    manage_rom: 'Manage ROM',
    manage_request: 'Manage Request',
    manage_feedback: 'Manage Feedback',
    manage_thought_leadership: 'Manage Vistra Inside Newsletter',
    manage_staff: 'Staff Account Maintenance',
    manage_client: 'Client Account Maintenance',
    manage_company_access: 'Company Access Maintenance',
    manage_applications: 'Applications',
  },
  login: {
    welcome: 'Welcome 欢迎',
    title: 'Online Company Secretary Platform',
    contact_us: 'Contact Us 联络我们',
    email: 'Email 电邮',
    password: 'Password 密码',
    forget_password: 'Forget Password? 忘记密码?',
    sign_in: 'Sign In 登录',
    reset_password: 'Reset Password',
    reset_password_description:
      'Enter your registered email below to receive password reset instruction',
    reset_password_success_description:
      'Email for password reset instruction sent. 已发出重置密码的电邮。',
    reset_password_expired: 'Reset Password',
    reset_password_expired_message:
      'We are sorry, the password reset link seems expired or invalid.',
    set_password_success: 'Set Password Success 密码已设置成功',
    set_password_success_message:
      'Please use the new password to sign in. 请用新密码登录。',

    set_password_title: 'Choose a new password 设置新密码',
    set_password_description:
      'A strong password consists of at least 12 characters that are a combination of uppercase letters, lowercase letters, numbers and special characters(@, #, $, %, etc.)\n高强度密码应该由至少 12 个字符组成，须包括大写字母、小写字母、数字及特殊字符（@、#、$、%等）的组合。',
    new_password: 'New password 新密码',
    confirm_password: 'Confirm password 确认密码',
    account_verification: 'Account Verification\n账号验证',
    account_verification_message:
      'Please enter the one-time verification code sent to your registered email to access your account.\n请输入发送到阁下登记电邮的一次性验证码以进入阁下的账号。',
    account_verification_message_sms:
      'Please enter the one-time verification code sent to your registered mobile number {{phoneNumber}}.\n请输入发送到阁下登记手机号码{{phoneNumber}} 的一次性验证码。',
    send_verification_code: 'Send Verification Code 发送验证码',
    resend_verification_code: 'Resend Verification Code 重新发送验证码',
    invalid_verification_code:
      'The verification code you entered is incorrect or expired. Please re-enter. 你输入的验证码不符或已失效，请重新输入。',
    confirm: 'Confirm 确认',
    request_new_code_in:
      'You may request for verification code or change account verification method after {{time}}\n阁下可于{{time}}后重新获取验证码或更改账号验证方式。',
    request_new_code_in_email:
      'You may request for verification code after {{time}}\n阁下可于{{time}}后重新获取验证码。',
  },
  relationship_manager: {
    client_management_team: 'Client Management Team',
    client_director: 'Client Director',
    contact_us: 'Contact Us',
    contact: 'Contact',
    office_address: 'Office Address',
    email: 'Email',
    phone: 'Phone',
    contact_tricor: 'Contact Vistra',
  },
  home: {
    stock_quotation: 'Stock Quotation',
    last_updated: 'Last Updated',
    delayed_quote: 'Delayed quote',
    events: 'Events',
    event_detail: 'Event Detail',
    events_placeholder: 'No upcoming events after {{date}}.',
    ccass_holding: 'CCASS Holdings',
    ownership_analytics: 'Ownership Analysis',

    create_an_event: 'Create an Event',
    edit_an_event: 'Edit an Event',
    title: 'Title',
    description: 'Details',
    upcoming: 'Upcoming',
    past: 'Past',
    date: 'Date',
    time: 'Time',
    year: 'Year',
    start_time: 'Start Time',
    end_time: 'End Time',
    start: 'Start',
    end: 'End',
    all_day: 'All Day',
    upcoming_event_detail: 'Event Detail',
    hk_public_holiday: 'HK Public Holiday',
    share_registration_and_issuer_services:
      'Share Registration & Issuer Services',
    registry_management: 'Registry Management',
    corporate_actions: 'Corporate Actions',
    shareholder_communications: 'Shareholder Communications',
    dividend_payment: 'Dividend Payment',
  },

  entity_management: {
    current_shareholders: 'Current Shareholders',
    list_of_entities: 'List of Entities',
    entity_list_placeholder: 'No entity',
    search_company_placeholder: 'Search by company name',
    select_company: 'Select Company',

    my_requests: 'My Requests',
    my_requests_placeholder: 'There are no upcoming tasks',
    my_requests_submitted: 'Submitted',
    my_requests_completed: 'Completed',
    company_summary: 'Company Summary',

    upcoming_tasks: 'Upcoming Tasks',
    upcoming_tasks_placeholder: 'There are no upcoming tasks',

    recent_documents: 'Recent Documents',
    recent_documents_placeholder: 'There are no recent documents',
    search_recent_documents_placeholder: 'Search by Description',
    date: 'Date',
    code: 'Code',
    task_title: 'Task Title',
    status: 'Status',
    active: 'Active',
    closed: 'Closed',
    closing: 'Closing',
    category: 'Category',
    bank__financial_statements_financial_statements: 'Financial Statements',
    statutory_legal_statutory_forms__submission:
      'Statutory/ Legal:Minutes and Resolutions:Director & Officer',
    statutory_legal_minutes_and_resolutions_director__officer:
      'Statutory/ Legal:Minutes and Resolutions:Director & Officer',

    description: 'Description',
    file_code: 'File Code',
    name: 'Name',
    company_name: 'Company Name',
    date_of_incorporation: 'Date of Incorporation',
    place_of_incorporation: 'Place of Incorporation',
    company_secretary: 'Company Secretary',
    registration_number: 'Registration No.',
    jurisdiction: 'Jurisdiction',
    british_virgin_islands: 'British Virgin Islands',
    cayman_islands: 'Cayman Islands',
    china: 'China',
    hong_kong: 'Hong Kong',
    labuan: 'Labuan',
    registered_office: 'Registered Office',
    incorpated_in: 'Incorporated in {{country}}',
    current_directors: 'Current Directors',
    select_dates: 'Select Dates',
    appointment_date: 'Appointment Date',
    resignation_date: 'Resignation Date',
    download_list_report: 'Download List Report',
    // Create Request
    create_request: 'Create Request',
    type_of_request: 'Type of Request:',
    please_input_details_below: 'Please input details below:',

    submit: 'Submit',
    cancel: 'Cancel',
    change_of_directors: 'Change of Directors',
    directors_particulars_change: 'Director’s Particulars Change',
    request_for_statutory_review: 'Request for Statutory Review',
    transfer_of_shares: 'Transfer of Shares',

    resignation_of_director: 'Resignation of Director',
    alternate_to: 'Alternate to:',
    alternate_director: 'Alternate Director',
    name_in_chinese: 'Name in Chinese:',
    name_in_english: 'Name in English:',
    alias_in_chinese: 'Alias in Chinese:',
    alias_in_english: 'Alias in English:',
    name_of_resigning_director: 'Name of resigning director',
    reason_of_cessation: 'Reason of Cessation:',
    resignation_or_others: 'Resignation / Others',
    deceased: 'Deceased',
    name_of_director: 'Name of director:',
    name_of_director_en: 'Name of director in English:',
    name_of_director_zh: 'Name of director in Chinese:',
    phone_number: 'Phone Number:',
    email_address: 'Email Address:',
    residential_address: 'Residential Address:',
    effective_date_of_change: 'Effective date of the change:',
    address_line: 'Address line {{number}}',
    hk_id_card_number: 'Hong Kong Identity Card Number:',
    passport_issuing_country: 'Passport Issuing Country / Region:',
    passport_number: 'Passport Number:',
    updated_particulars:
      'Updated Particulars (Please complete item(s) with change(s) only)',
    supporting_documents_needed: 'Supporting Documents Needed',
    supporting_documents: 'Supporting Documents',
    certified_id_copy_if_changed:
      'Certified true copy of HKID card/ passport (if HKID/ passport has changed):',
    certified_id_copy: 'Certified true copy of HKID card/ passport:',
    proof_of_address_if_changed: 'Proof of Address (if address has changed):',
    proof_of_address: 'Proof of Address:',
    name_of_audit_firm_name: 'Name of Audit Firm:',
    name_of_contact_person: 'Name of Contact Person:',
    preferred_date_time: 'Preferred date time:',
    preferred_time: 'Preferred time:',
    years_of_compnay_records_to_be_reviewed:
      'Years of company records to be reviewed:',
    consideration_to_be_received_or_paid: 'Consideration to be received/ paid:',
    type_of_review: 'Type of Review:',

    obtain_ecopies: 'Obtain e-copies',
    onsite_visit: 'Onsite visit',

    transferor: 'Transferor',
    transferee: 'Transferee',
    name_of_transferor: 'Name of Transferor:',
    name_of_transferee: 'Name of Transferee:',
    transferor_address: 'Address of Transferor:',
    transferee_address: 'Address of Transferee:',
    occupation_of_transferor: 'Occupation of Transferor:',
    occupation_of_transferee: 'Occupation of Transferee:',
    number_of_shares_to_be_transferred: 'Number of Shares to be Transferred:',
    value_of_transfer: 'Value/ Considerations of Transfer:',
    effective_date_of_transfer: 'Effective Date of the Transfer:',
    sale_and_purchase_agreement: 'Sale and Purchase Agreement:',
    sale_and_purchase_agreement_if_any: 'Sale and Purchase Agreement (If Any):',
    latest_consolidated_statesment:
      'Latest Consolidated Audited Financial Statements of the Company:',
    latest_management_accounts: 'Latest Management Accounts of the Company:',

    registered_address: 'Registered Address:',
    directors: 'Directors',
    shareholders: 'Holders',

    re_notice_cod: 'Re: Notice of Change in Particulars of Company Secretary',
    re_notice_dpc: 'Re: Notice of Director’s Particulars Change',
    re_notice_rsr: 'Re: Notice of Request for Statutory Review',
    re_notice_ts: 'Re: Notice of Transfer of Shares',

    request_of_type: 'Request of {{type}}',

    submitted: 'Submitted',
    completed: 'Completed',
    rejected: 'Rejected',
    pending: 'Pending',
  },
  manage_reports: {
    reports: 'Reports',
    audit_trail_report: 'Audit Trail Report',
    user_report: 'User Report',
  },
  manage_company_access: {
    back: 'Back to list of companies',
    maker_checker_function: 'Maker-Checker function',
    max_number_of_acc: 'Max. Number of Accounts',
    acc_number_limitation: 'Account numbers limitation',
  },
  manage_company: {
    back: 'Back to list of companies',
    select_company: 'Select Company',
    select_company_placeholder: 'No company',
    date: 'Date',
    file_code: 'File Code',
    email: 'Email',
    name: 'Name',
    area_code: 'Country/Area Code',
    phone_number: 'Mobile Number',
    status: 'Status',
    registered_office: 'Registered Office',

    access_rights_list: 'Access Rights List',
    existing_users: 'Existing Users',
    existing_users_placeholder: 'No Existing Users',
    company_profile: 'Company Profile',

    create_access_right: 'Create Access Right',
    edit_access_right: 'Edit Access Right',

    select_users: 'Select Users',
    add_user: 'Add User',
    remove_user: 'Delete Account',
    remove_user_message: 'Are you sure you want to remove user {{mail}}?',
  },
  manage_applications: {
    applications: 'Applications',
    pending_for_approval: 'Pending for Approval',
    approved: 'Approved',
    rejected: 'Rejected',
    pending_for_approval_company: 'Pending for Approval(Company)',
    approved_company: 'Approved(Company)',
    rejected_company: 'Rejected(Company)',
    name_email: 'Name (Email)',
    account_type: 'Acct. Type',
    user_type: 'User Type',
    date: 'Date',
    requested_by: 'Requested by',
    button_approve: 'Approve',
    button_reject: 'Reject',
    button_send_reminder: 'Send Reminder',
    button_send: 'Send',
    approval_status: 'Approval Status',
    reject_reason: 'Reject Reason',
    reject_note:
      'Note: Below reject reason will apply to all selected applications.',
    select_checkers: 'Select checker(s)',
    button_cancel: 'Cancel',
    button_yes: 'Yes',
    button_no: 'No',
  },
  manage_rom: {
    select_company: 'Select Company',
    select_company_placeholder: 'No company',
    back: 'Back to list of ROM companies',
    select_user: 'Select User',
    add_user: 'Add User',
  },
  manage_user: {
    import: 'Import',
    users_data_download: 'Users Data Download',
    back: 'Back to list of users',
    back_staff: 'Back to list of staffs',
    back_company: 'Back to list of companies',
    select_user: 'Select User',
    select_user_placeholder: 'No users',
    name: 'Name',
    email: 'Email',
    phone_number: 'Phone number',
    status: 'Status',
    profile: 'User Profile',
    user_type: 'User Type',
    max_account_number: 'Max. Number of Accounts',
    register_of_members: 'Register of Members',
    entity_management: 'Entity Management',
    contact_point: 'Contact Point',
    create_user: 'Create User',
    user_info: 'Account Information',
    access_rights: 'Access Rights',
    last_login: 'Last Login',
    created_at: 'Created At',
    account_type: 'Acc type',
    requested_by: 'Requested by',

    rom_stock_codes: 'ROM stock code',
    file_codes: 'File codes',
    contact_person: 'Contact Person',
    client_type: 'Client Type',

    delete_user: 'Delete Account',
    delete_user_message:
      'Are you sure you want to delete account {{mail}}?\nThis cannot be undone.',

    staffs: 'Staffs',
    staffs_placeholder: 'No staff',
    rom_placeholder: 'No company',
    entity_management_placeholder: 'No company',
    relationship_manager: 'Relationship Manager',
    management_team: 'Management Team',
    select_new_relationship_manager: 'Select New Relationship Manager',
    please_select_rm: 'Please Select Relationship Manager',
    primary: 'Primary',
    set_as_primary: 'Set as Primary',
    remove: 'Remove',

    unlock: 'Unlock',
    resend: 'Resend',
    invitationsent: 'Invited',
    active: 'Active',
    locked: 'Locked',
    reset_password: 'Reset Password',
    pending: 'Pending for Approval',
    rejected: 'Rejected',

    hk_ticker: 'HK Ticker',
    insert_hk_ticker: 'Insert HK Ticker (e.g. 00001)',
    add: 'Add',

    error_mobile_format: 'Please enter mobile number with correct format',
    error_email_already_registered: 'This email has been registered',

    tricor_checker: 'Staff Checker',
    client_checker: 'Client Checker',
    assigned_client_checker: 'Assigned Client Checker',
    assigned_tricor_checker: 'Assigned Staff Checker',
  },
  manage_staff: {
    back: 'Back to list of staffs',
    select_staff: 'Select Staff',
    select_staff_placeholder: 'No staff',
    create_staff: 'Create Staff',
    name: 'Name',
    email: 'Email',
    status: 'Status',
    role: 'Role',
    dept: 'Dept',
    acc_type: 'Acc type',
    select_user_type: 'Select User Type',
    department: 'Department',
    last_login: 'Last Login',
    created_at: 'Created At',

    access_right_entity_management: 'EM',
    access_right_register_of_member: 'ROM',
    access_right_ccass_holding: 'CCASS Holding',
    access_right_reports: 'Reports',
    access_right_ownership_analysis: 'Ownership Analysis',
    access_right_industry_analysis: 'Industry Analysis',
    access_right_eagm: 'eAGM',
    access_right_eproxy: 'eProxy',
    access_right_board_portal: 'BoardFolio',
    access_right_tin: 'TIN',
    access_right_market_intelligence: 'Market Intelligence',
    access_right_invoice_management: 'Invoice Management',
    access_right_thought_leadership: 'Thought Leadership',

    add_client: 'Add Client',
    delete_user: 'Delete Account',
    delete_user_message:
      'Are you sure you want to delete account {{mail}}?\nThis cannot be undone.',

    unlock: 'Unlock',
    resend: 'Resend',
    invitationsent: 'Invited',
    active: 'Active',
    locked: 'Locked',
    reset_password: 'Reset Password',

    staff_profile: 'Account Information',
    existing_clients: 'Existing Clients',
    existing_clients_placeholder: 'No Existing Clients',
    edit_role: 'Edit Role',
  },
  manage_request: {
    manage_request: 'Manage Request',
    request_type: 'Request Type',
    date: 'Date',
    company: 'Company',
    task_title: 'Task Title',
    status: 'Status',
  },
  manage_thought_leadership: {
    manage_thought_leadership: 'Manage Vistra Inside Newsletter',
    created_at: 'Created At',
    title: 'Title',
    description: 'Description',
    image: 'Image',
    weblink: 'Link',
    create_article: 'Create Article',
    edit_article: 'Edit Article',
    delete_article: 'Delete Article',
    delete_article_message:
      'Are you sure you want to delete this article?\nThis cannot be undone.',
  },
  name_card: {
    office_address: 'Office Address',
    name: 'Name',
    email: 'Email',
    phone: 'Phone',
  },
  register_of_members: {
    stock_code: 'Stock Code',
    select_company: 'Select Company',
    search_company_placeholder: 'Search by company name',
    issue_summary: 'Issue Summary',
    holders_list_report: 'Holders List',
    holder_list: 'Holders List',
    holder_list_placeholder: 'No Search Results',
    holder_list_search_placeholder:
      'Search includes Holder Name, Account Number, Certificate Number',
    details: 'Details',
    issued_share_movement_history: 'Issued Securities Movement History',
    share_movement_history_placeholder: 'No Search Results',
    share_movement_history_search_placeholder: 'Search by nature',
    start_date: 'Start Date',
    end_date: 'End Date',
    transaction_amount: 'Transaction Amount',
    transaction_unit_or_shares: 'Transaction Shares/Units',
    member_analysis: 'Member Analysis',
    analysis: ' Analysis',
    no_of_holders: 'Number of Holders',
    issued_capital: 'Issued Shares/Units',
    par_value: 'Par Value',
    trading_counter: 'Trading Counter',
    ecorporate_communication: 'eCorporate Communications',
    registered_holders: 'eCorporate Communication:\nRegistered Holders',
    hkscc_non_registered_holders:
      'eCorporate Communication:\nHKSCC Non-Registered Holders',
    select_no_of_shareholders: 'Number of Holders',
    request_for_service: 'Contact us to activate',
    sort: 'Sort',

    type_of_report: 'Type of Report',
    full_list: 'Full List',
    top_holder_list: 'Top Holder List',
    number_of_holders: 'Number of Holders',
    number_of_registered_holders: 'number_of_holders',
    all: 'All',
    top: 'Top',
    last: 'Last',
    sort_by: 'Sort By',
    sort_order: 'Sort Order',
    asc: 'Ascending',
    desc: 'Descending',
    holders: 'Holders',
    fields: 'Fields',
    account_number: 'Account No.',
    download_holders_report: 'Download Holders List',
    download_report: 'Download Report',
    holding_balance: 'Holding Balance',
    folio_no: 'Account No.',
    hdr_name: 'Holder Name',
    hdr_name_1: 'Holder Name',

    class_of_securities: 'Type of Securities:',
    ordinary_shares: 'Ordinary Shares',
    preference_shares: 'Preference Shares',
    warrants: 'Warrants',
    dual_currency_securities: 'Dual Currency Securities',

    symbol: 'Symbol',
    account_no: 'Account Number',
    cert_no: 'Certificate Number',
    no_of_certs: 'Number of Certificates',
    certificates_detail: 'Certificates Details',
    no_shares_units: 'Number of shares/units',
    cert_value: 'Value',
    holder_name: 'Holder Name',
    name: 'Name',
    address: 'Address',
    balance: 'Balance',
    country_area: 'Country/Area',
    security_class: 'Security Class',
    means_of_communication: 'Means of Receiving Corporate Communications',
    access_through_others: 'Others',
    access_through_website: 'Access Through Website',
    access_through_physical_copy: 'Physical Copy',
    special: 'Special',
    new_holders: 'New Holders',
    physical_copy_in_english_and_chinese:
      'Physical copy in English and Chinese',
    physical_copy_in_english: 'Physical copy in English',
    physical_copy_in_chinese: 'Physical copy in Chinese',
    notification_by_mail: 'Notification by Mail',
    notification_by_email: 'Notification by email',
    dividend_election_instruction: 'Permanent Election for Cash/Scrip Dividend',
    no_instruction: 'No Instruction',
    permanent_election_for_scrip: 'Permanent Election for Scrip',
    permanent_election_for_cash: 'Permanent Election for Cash',
    autopay_instruction: 'Means of Receiving Cash Dividend',
    automatic_payment: 'Automatic Payment',
    cheque: 'Cheque',
    currency_for_cash_dividend: 'Permanent Currency Election for Cash Dividend',
    currency_election: 'Permanent Currency Election for Cash Dividend',
    registered_holders_label: 'Registered Holders (“RHs”)',
    holder_information: 'Holder Information',
    holder_details: 'Holder Details',
    preferences: 'Holder Preferences',

    date_of_event: 'Date of Event',
    nature: 'Nature',
    issued_securities_balance: 'Issued Securities Balance',
    select_category: 'Select Category',
    overseas_holders_analysis: 'Geographic Analysis',
    total_holders: 'Total Holders',
    percentage_of_holders: '% of Holders',
    total_holdings: 'Total Holdings',
    percentage_of_issued_securities: '% of Issued Securities',
    payment_instruction: 'Dividend Payment Instructions',
    currency: 'Currency',

    means_of_receiving_corporate_communications:
      'Means of Receiving Corporate Communications',
    number_of_registered_holders: 'Number of Registered Holders',
    new_holders: 'New Holders',
    others: 'Others',
    email_notification_on_publication_of_corporate_communications:
      'Email Notification',
    physical_otification_on_publication_of_corporate_communications:
      'Physical Notification',
    physical_copy_in_english_and_chinese_html: 'English and Chinese',
    physical_copy_in_english_html: 'English',
    physical_copy_in_chinese_html: 'Chinese',
    automatic_payment_html: '<span class=red>Automatic payment</span>',
    cheque_html: '<span class=red>Cheque</span>',
    to_receive_dividend_in_scrip_html: 'Scrip',
    to_receive_dividend_in_cash_html: 'Cash',
    no_instruction_given_html: 'No instruction',
    no_instruction_html: '<span class=red>No instruction</span>',
    total: 'Total',
    not_applicable: 'Not Applicable',
    has_corp_comm: 'Yes',

    range_of_holdings_analysis: 'Range of Holdings Analysis',
    range_of_holdings: 'Range of Holdings',
    above: 'Above',
    to: 'to',
    from: 'From',
    generate_range_of_holdings_report: 'Generate Range of Holdings Report',

    error_input_value_should_be_larger_than:
      'Input value should be larger than {{value}}',
  },
  thought_leadership: {
    featured_articles: 'Featured Articles',
  },
  ccass_holding: {
    holder_list_placeholder: 'No search result. Please select another date.',
    shareholding_by_holder_types_percent: 'Shareholding by Holder Types %',
    top_10_daily_holding_changes_in_ccass:
      'Top {{count}} Daily Holding Changes (For the Last Business Day) in CCASS',
    top_10_holders_in_ccass: 'Top {{count}} Holders in CCASS',
    participant_name: 'Participant Name',
    last_holding_date: 'Last Holding Date',
    shareholding: 'Shareholding',
    change: 'Change',
    percent_holding: '% Holding',
    percent_change: '% Change',
    percent_holdings_change: '% Holdings Change',

    ccass_participant_information: 'CCASS Participant Information',
    participant_details: 'Participant Details',
    participant_id: 'Participant ID',
    ccass_participant_name: 'Participant Name',
    address: 'Address',
    percent_of_share: '% of the total number of Issued Shares/ Warrants/ Units',
    southbound_holding: 'Southbound Holding',
    select_last_holding_date: 'Select Last Holding Date',
    data_not_available:
      'Data is not available to your selected date, please select another date.',
    date_is_holiday: 'Date must be a business day.',
  },
  reports: {
    request_new_report: 'Request New Report',
    scheduled: 'Scheduled',
    on_demand: 'On Demand',
    transfer_journal: 'Transfer Journal',
    top_holders_list: 'Top Holders List',
    holders_list: 'Holders List',
    coming_soon: 'Coming Soon',
    oversea_holders_list: 'Oversea Holders List',
    allotment_report: 'Allotment Report',
    buyback_report: 'Securities Cancellation Report',
    other_report: 'Other Reports',
    select_year: 'Select Year',
    historical_on_demand_report: 'Historical On Demand Reports',
    file_name: 'File Name',
    date: 'Date',
    date_or_period: 'Date/Period',
    all: 'All',
    not_available: 'Not Available',

    please_select_required_report: 'Please select the required report',
  },
  boardfolio: {
    boardfolio_intro:
      ' An advanced board portal solution that makes board meeting easy and enables immediate and seamless collaboration between a board of directors and management',
    what_is_boardfolio: 'What is BOARDFOLIO?',
    what_is_boardfolio_description:
      'Boardfolio is an advanced board portal solution from Vistra that makes board meetings easy and enables immediate and seamless collaboration between a Board of Directors and management. It is a secure and centralized repository of all materials pertaining to board meetings and offers administrative ease for board members and administrators alike.',
    advanced_corporate_governance: 'Advanced corporate governance',
    advanced_corporate_governance_description:
      'Boardfolio enables companies to meet standards of corporate governance, compliance, and secure collaboration and provides for a seamless transfer of manual/paper-based board meeting processes to a secure digital platform. Advanced document security and data encryption allows senior management to take quick and comprehensive decisions.',
    seamless_meeting_management: 'Seamless end-to-end meeting management',
    seamless_meeting_management_description:
      'Boardfolio is designed to solve a multitude of problems for board meeting management through geographical mobility, multi-device support, granular access control, and real-time data sharing. The meeting solutions include easy board pack creation, agenda building, attendance recorder, minutes publication, and action items management. Administrators will find simplification of tasks at all stages of meeting organization (pre-meeting, in-meeting, and post-meeting), and significant savings in both time and costs.',
    what_are_the_benfits: 'What are the benefits of BOARDFOLIO?',
    security_and_access_control: 'Security And Access Control',
    security_and_access_control_description:
      '<ul><li>Advanced data encryption</li><li>Enhanced confidentiality and privacy</li><li>Customized access permissions</li><li>Granular file-level control (view / download / annotate)</li></ul>',
    seamless_collaboration: 'Seamless Collaboration',
    seamless_collaboration_description:
      ' <ul><li>Instant cloud-based access to board materials</li><li>Virtual work-groups for committee collaborations</li><li>Commenting and discussion tools</li><li>Easy implementation of special projects/teams</li></ul>',
    enhanced_productivity_and_efficiency:
      'Enhanced Productivity And Efficiency',
    enhanced_productivity_and_efficiency_description:
      '<ul><li>Dashboard for task summaries and quick access</li><li>Comprehensive task and project tracking</li><li>Automatic document versioning</li><li>Easy board pack creation</li><li>Simultaneous management of multiple projects</li></ul>',
    adminstrative_ease: 'Adminstrative Ease',
    adminstrative_ease_description:
      '<ul><li>Organize meetings, set-up agendas, and board packs</li><li>Quick recording of minutes and attendance</li><li>Publish minutes instantly, assign tasks, and archive meetings</li></ul>',
    how_can_tricor_help: 'How Can Vistra Help?',
    how_can_tricor_help_description:
      'With Boardfolio, Vistra offers you an advanced solution based on latest technologies that makes management of your sensitive board information easier. You can rely on our highly secure hosting solutions that helps to ensure your data is always protected, and accessible.\n\nWe engage independent experts to perform frequent security audits and ensure integrity of processes and best practices. Boardfolio is fully GDPR compliant and certified for the Verasafe Privacy Seal, giving you full access to third-party data requests. Find confidence in data encryption at all levels and transit processes.\n\nVistra partners with you to enhance your competitiveness and generate business value. We offer on-boarding guidance, a dedicated client success manager and highly responsive customer support. Boardfolio is board meetings made easy and partnering with us will take your board process management to the next-level.',
  },
  eproxy: {
    eproxy_intro:
      'An ultimate technology solution for managing Hybrid Meetings with ease and flexibility',
    what_is_eproxy:
      'How to enhance investor relations with a secure electronic proxy system?',
    what_is_eproxy_description:
      'To ensure good corporate governance, it is important to engage as many shareholders as possible in the undertaking of major decisions. However, it is difficult to guarantee the attendance of all board members and shareholders due to a variety of reasons. Globally, companies have witnessed increased participation when e-Meeting (either hybrid or virtual) and e-Proxy facilities are in place. e-Proxy system comes to the aid by providing a structured and secure online platform for shareholders to process electronic proxy appointments and voting instructions.',
    what_are_the_benfits: 'Value To Business',
    streamlining_management_of_proxies: 'Streamlining Management Of Proxies',
    streamlining_management_of_proxies_description:
      '<ul><li>Real-time information of proxy appointments and voting instructions for immediate revision</li><li>Comprehensive proxy summary to avoid occasional omission of voting by major shareholders or other friendly individual or institutional investors</li></ul>',
    facilitating_investor_relations: 'Facilitating Investor Relations',
    facilitating_investor_relations_description:
      '<ul><li>Latest voting instruction information for necessary fine tune of investor relationship and proxy solicitation policies</li><li>e-Communications with shareholders to drive engagement</li></ul>',
    enhancing_corporate_governance: 'Enhancing Corporate Governance',
    enhancing_corporate_governance_description:
      '<ul><li>Increased engagement of shareholders in the undertaking of major decisions</li><li>Better corporate image by providing an electronic and intuitive system to shareholders for proxy deliveries</li></ul>',
    enjoying_security_and_efficiency: 'Enjoying Security And Efficiency',
    enjoying_security_and_efficiency_description:
      '<ul><li>An encrypted server network to keep all information submitted by shareholders private and confidential</li><li>Electronic proxy appointments / instructions submitted in a secured and efficient manner</li></ul>',
    how_can_tricor_help: 'How Can Vistra Help?',
    how_can_tricor_help_description:
      'Vistra offers cohesive e-Meeting and e-Proxy solutions as a one-stop service provider to ensure seamless holding and chairing of general meetings, as well as successful on-site and/or online voting and validation of results. Our dedicated team can also help you review and amend the Articles of Association to make electronic general meetings and proxies possible.',
    key_features_of_eproxy: 'Key Features of E-PROXY',
    key_features_1:
      'Shareholders can review entitled holdings on a daily basis',
    key_features_2:
      'Input at any time (24/7) before the submission deadline with one-clicking at the convenience of the shareholders',
    key_features_3:
      'Multiple input functions are available including appointment of single/multiple proxies with or without voting instructions, cancellation and amendment of proxy appointments/voting instructions before the designated deadline',
    key_features_4:
      'Shareholders are able to download receipt in relation to the successfully submitted proxy appointments/voting instructions for record',
    key_features_5:
      'Facilitate CCASS to submit their participants’ proxy appointments/instructions electronically',
  },
  eagm: {
    hybrid_meeting: 'Hybrid Meeting',
    what_is_hybrid_meeting:
      'An ultimate technology solution for managing Hybrid Meetings with ease and flexibility',
    what_is_hybrid_meeting_description:
      'Hybrid Meeting combines traditional in-room general meetings with virtual meeting set-up in parallel, to offer a unified meeting platform. Leveraging on advanced technologies, the hybrid meeting facilitates members to participate either in-person or online. It creates a more efficient way for members to attend meetings and to effectively manage communication and voting matters. The adoption of virtual (online only) and hybrid (online and in-person) meetings is a major step in encouraging members to participate without incurring the challenges of physical attendance. Such meetings are highly scalable, offering significant cost savings and reduction in administrative effort as well as enhancing member engagement and transparency. SPOT allows members to vote online, view live streaming of the meeting and ask questions online. The experience is similar to what they normally do at a physical meeting without the need of physical attendance. Secure online voting and instant results are a key advantage. In light of the world facing environmental and public health challenges at an increasing pace with frequent imposition of travel restriction, SPOT offers excellent support in such unexpected scenarios. This allows for meetings to proceed as planned from secure location(s) and through our secure system.',
    hybrid_meeting_what_are_the_benfits:
      'What are the benefits of Hybrid Meeting?',
    member_engagement: 'Member Engagement',
    member_engagement_description:
      '<ul><li>Virtual attendance for members who cannot attend physically</li><li>Access across multiple devices & platforms at anytime</li><li>Rights and benefits same as with physical participation</li></ul>',
    real_time_collaboration: 'Real-Time Collaboration',
    real_time_collaboration_description:
      '<ul><li>Digital tool to improve collaborative experience</li><li>Questions by attendees managed & shared with chairman /attendees</li><li>Multi-feed live streaming & content sharing</li><li>Instant online voting & submission of votes</li><li>Chairman portal view</li></ul>',
    transparency_and_security: 'Transparency And Security',
    transparency_and_security_description:
      '<ul><li>Publish voting results instantly</li><li>Address questions immediately with direct interaction with the Board</li><li>Accuracy of voting & reporting</li><li>Secure access</li><li>Encrypted technology with cloud infrastructure</li></ul>',
    saving_and_efficiency: 'Saving And Efficiency',
    saving_and_efficiency_decscription:
      '<ul><li>Save significant travelling time with electronic voting</li><li>Save costs with virtual meetings</li><li>Scalability with unlimited number of participants</li><li>Increase efficiency through virtual engagement</li></ul>',
    spot_is_ultimate_solution:
      'SPOT IS THE ULTIMATE SOLUTION FOR HYBRID MEETINGS',
    spot_is_ultimate_solution_description:
      'Vistra is a market leader for meeting management and poll scrutineer services. We are a highly trusted partner to ensure listed issuers are in full compliance with all relevant regulatory requirements. SPOT is built from over 20 years of experience servicing thousands of clients’ general meetings. We administer the whole meeting process for you, starting with reviewing all meeting documentations, meeting notices, online meeting user guide, meeting procedures, chairman scripts to testing the system ahead of the meeting and going through meeting procedures with the chairman.\n\nVistra’s highly scalable technology operates on a secure and dedicated network. All confidential data in both storage and transit are encrypted with industry-standard encryption. Additionally, Vistra offers ISO 27001 certified cloud computing technology and infrastructure to ensure reliable services and auto-failover mechanism to support high availability of system.',

    eproxy_intro: 'E-PROXY',
    what_is_eproxy:
      'How to enhance investor relations with a secure electronic proxy system?',
    what_is_eproxy_description:
      'To ensure good corporate governance, it is important to engage as many shareholders as possible in the undertaking of major decisions. However, it is difficult to guarantee the attendance of all board members and shareholders due to a variety of reasons. Globally, companies have witnessed increased participation when e-Meeting (either hybrid or virtual) and e-Proxy facilities are in place. e-Proxy system comes to the aid by providing a structured and secure online platform for shareholders to process electronic proxy appointments and voting instructions.',
    what_are_the_benfits: 'Value To Business',
    streamlining_management_of_proxies: 'Streamlining Management Of Proxies',
    streamlining_management_of_proxies_description:
      '<ul><li>Real-time information of proxy appointments and voting instructions for immediate revision</li><li>Comprehensive proxy summary to avoid occasional omission of voting by major shareholders or other friendly individual or institutional investors</li></ul>',
    facilitating_investor_relations: 'Facilitating Investor Relations',
    facilitating_investor_relations_description:
      '<ul><li>Latest voting instruction information for necessary fine tune of investor relationship and proxy solicitation policies</li><li>e-Communications with shareholders to drive engagement</li></ul>',
    enhancing_corporate_governance: 'Enhancing Corporate Governance',
    enhancing_corporate_governance_description:
      '<ul><li>Increased engagement of shareholders in the undertaking of major decisions</li><li>Better corporate image by providing an electronic and intuitive system to shareholders for proxy deliveries</li></ul>',
    enjoying_security_and_efficiency: 'Enjoying Security And Efficiency',
    enjoying_security_and_efficiency_description:
      '<ul><li>An encrypted server network to keep all information submitted by shareholders private and confidential</li><li>Electronic proxy appointments / instructions submitted in a secured and efficient manner</li></ul>',
    how_can_tricor_help: 'How Can Vistra Help?',
    how_can_tricor_help_description:
      'Vistra offers cohesive e-Meeting and e-Proxy solutions as a one-stop service provider to ensure seamless holding and chairing of general meetings, as well as successful on-site and/or online voting and validation of results. Our dedicated team can also help you review and amend the Articles of Association to make electronic general meetings and proxies possible.',
    key_features_of_eproxy: 'Key Features of E-PROXY',
    key_features_1:
      'Shareholders can review entitled holdings on a daily basis',
    key_features_2:
      'Input at any time (24/7) before the submission deadline with one-clicking at the convenience of the shareholders',
    key_features_3:
      'Multiple input functions are available including appointment of single/multiple proxies with or without voting instructions, cancellation and amendment of proxy appointments/voting instructions before the designated deadline',
    key_features_4:
      'Shareholders are able to download receipt in relation to the successfully submitted proxy appointments/voting instructions for record',
    key_features_5:
      'Facilitate CCASS to submit their participants’ proxy appointments/instructions electronically',

    stock_code: 'Stock Code',
    company: 'Company/Organization',
    meeting_type: 'Meeting Type',
    meeting_time: 'Meeting time',
    search_placeholder: 'Search',
    welcome_to_spot: 'Welcome to SPOT (Vistra e-Meeting System)',
  },
  currency: {
    singapore_dollars: 'Singapore Dollar - SGD',
    japanese_yen: 'Japanese Yen - JPY',
    korean: 'South Korean Won - KRW',
    hong_kong_dollars: 'HKD',
    united_states_dollars: 'United States Dollar - USD',
    australia: 'Australian Dollar - AUD',
    chinese_rmb: 'Renminbi - CNY',
    norway: 'Norwegian Krone - NOK',
    european: 'Euro - EUR',
    malaysian: 'Malaysian Ringgit - MYR',
    canada: 'Canadian Dollar - CAD',
    british: 'Pound Sterling - GBP',
    chinese_yuan: 'Renminbi - CNY',
  },
  country: {
    bermuda_or_cayman_islands: 'BERMUDA / CAYMAN ISLANDS',
    'bermuda_or_hong_kong_re-domiciled': 'BERMUDA / HONG KONG RE-DOMICILED',
    bermuda_liquidation: 'BERMUDA LIQUIDATION',
    'bvi_or_cayman_islands_re-domiciled': 'BVI / CAYMAN ISLANDS RE-DOMICILED',
    cayman_islands_or_bermuda: 'CAYMAN ISLANDS / BERMUDA',
    'cayman_islands_or_bermuda_re-domiciled':
      'CAYMAN ISLANDS / BERMUDA RE-DOMICILED',
    'cayman_islands_or_bermuda_re-domiciled_liquidation':
      'CAYMAN ISLANDS / BERMUDA RE-DOMICILED LIQUIDATION',
    'cayman_islands_or_hong_kong_re-domiciled':
      'CAYMAN ISLANDS / HONG KONG RE-DOMICILED',
    cayman_islands_liquidation: 'CAYMAN ISLANDS LIQUIDATION',
    'singapore_or_hong_kong_re-domiciled': 'SINGAPORE / HONG KONG RE-DOMICILED',
    albania: 'ALBANIA',
    algeria: 'ALGERIA',
    angola: 'ANGOLA',
    'anguilla,_b.w.i.': 'ANGUILLA, B.W.I.',
    argentina: 'ARGENTINA',
    armenia: 'ARMENIA',
    '(z)_australia': 'AUSTRALIA',
    australia: 'AUSTRALIA',
    austria: 'AUSTRIA',
    bahamas: 'BAHAMAS',
    bahrain: 'BAHRAIN',
    bangladesh: 'BANGLADESH',
    barbados: 'BARBADOS',
    belgium: 'BELGIUM',
    belize: 'BELIZE',
    bermuda: 'BERMUDA',
    bhutan: 'BHUTAN',
    bolivia: 'BOLIVIA',
    botswana: 'BOTSWANA',
    brazil: 'BRAZIL',
    british_isles: 'BRITISH ISLES',
    '(z)_british_virgin_islands': 'BRITISH VIRGIN ISLANDS',
    british_virgin_islands: 'BRITISH VIRGIN ISLANDS',
    british_west_indies: 'BRITISH WEST INDIES',
    brunei: 'BRUNEI',
    bulgaria: 'BULGARIA',
    burkina_faso: 'BURKINA FASO',
    burma: 'BURMA',
    burundi: 'BURUNDI',
    cambodia: 'CAMBODIA',
    canada: 'CANADA',
    cayman_islands: 'CAYMAN ISLANDS',
    central_african_republic: 'CENTRAL AFRICAN REPUBLIC',
    '(z)_channel_islands': 'CHANNEL ISLANDS',
    channel_islands: 'CHANNEL ISLANDS',
    chile: 'CHILE',
    '(z)_china': 'CHINA',
    '*china_guangdong_province': 'CHINA',
    china: 'CHINA',
    colombia: 'COLOMBIA',
    congo: 'CONGO',
    cook_islands: 'COOK ISLANDS',
    costa_rica: 'COSTA RICA',
    croatia: 'CROATIA',
    cuba: 'CUBA',
    curacao: 'CURACAO',
    cyprus: 'CYPRUS',
    czech_republic: 'CZECH REPUBLIC',
    democratic_yemen: 'DEMOCRATIC YEMEN',
    denmark: 'DENMARK',
    djibouti: 'DJIBOUTI',
    dominican_republic: 'DOMINICAN REPUBLIC',
    ecuador: 'ECUADOR',
    egypt: 'EGYPT',
    eire: 'EIRE',
    el_salvador: 'EL SALVADOR',
    equatorial_guinea: 'EQUATORIAL GUINEA',
    ethiopia: 'ETHIOPIA',
    fiji: 'FIJI',
    finland: 'FINLAND',
    '(z)_france': 'FRANCE',
    france: 'FRANCE',
    gabon: 'GABON',
    gambia: 'GAMBIA',
    german_democratic_republic: 'GERMAN DEMOCRATIC REPUBLIC',
    '(z)_germany': 'GERMANY',
    germany: 'GERMANY',
    ghana: 'GHANA',
    gibraltar: 'GIBRALTAR',
    gilbert_islands: 'GILBERT ISLANDS',
    greece: 'GREECE',
    grenada: 'GRENADA',
    guam: 'GUAM',
    guatemala: 'GUATEMALA',
    guernsey: 'GUERNSEY',
    guinea: 'GUINEA',
    'guinea-bissau': 'GUINEA-BISSAU',
    guyana: 'GUYANA',
    haiti: 'HAITI',
    honduras: 'HONDURAS',
    '(z)_hong_kong': 'HONG KONG',
    'hk-special_delivery_(z1)': 'HONG KONG',
    'hk-special_delivery_(z10)': 'HONG KONG',
    'hk-special_delivery_(z11)': 'HONG KONG',
    'hk-special_delivery_(z12)': 'HONG KONG',
    'hk-special_delivery_(z13)': 'HONG KONG',
    'hk-special_delivery_(z14)': 'HONG KONG',
    'hk-special_delivery_(z15)': 'HONG KONG',
    'hk-special_delivery_(z1b)': 'HONG KONG',
    'hk-special_delivery_(z2)': 'HONG KONG',
    'hk-special_delivery_(z3)': 'HONG KONG',
    'hk-special_delivery_(z4)': 'HONG KONG',
    'hk-special_delivery_(z5)': 'HONG KONG',
    'hk-special_delivery_(z6)': 'HONG KONG',
    'hk-special_delivery_(z7)': 'HONG KONG',
    'hk-special_delivery_(z8)': 'HONG KONG',
    'hk-special_delivery_(z9)': 'HONG KONG',
    hong_kong: 'HONG KONG',
    'hong_kong_(c)': 'HONG KONG',
    hungary: 'HUNGARY',
    iceland: 'ICELAND',
    '(z)_india': 'INDIA',
    india: 'INDIA',
    indonesia: 'INDONESIA',
    iran: 'IRAN',
    iraq: 'IRAQ',
    '(z)_ireland': 'IRELAND',
    ireland: 'IRELAND',
    'ireland_(irl)': 'IRELAND',
    '(z)_isle_of_man': 'ISLE OF MAN',
    isle_of_man: 'ISLE OF MAN',
    israel: 'ISRAEL',
    italy: 'ITALY',
    ivory_coast: 'IVORY COAST',
    jamaica: 'JAMAICA',
    japan: 'JAPAN',
    '(z)_jersey': 'JERSEY',
    jersey: 'JERSEY',
    '(z)_jordan': 'JORDAN',
    jordan: 'JORDAN',
    kenya: 'KENYA',
    kingdom_of_cambodia: 'KINGDOM OF CAMBODIA',
    korea: 'KOREA',
    kowloon: 'KOWLOON',
    kuwait: 'KUWAIT',
    laos: 'LAOS',
    lebanon: 'LEBANON',
    lesotho: 'LESOTHO',
    liberia: 'LIBERIA',
    libya: 'LIBYA',
    liechtenstein: 'LIECHTENSTEIN',
    luxembourg: 'LUXEMBOURG',
    macau: 'MACAU',
    madagascar: 'MADAGASCAR',
    malawi: 'MALAWI',
    '(z)_malaysia': 'MALAYSIA',
    malaysia: 'MALAYSIA',
    maldives: 'MALDIVES',
    mali: 'MALI',
    '(z)_malta': 'MALTA',
    malta: 'MALTA',
    marshall_islands: 'MARSHALL ISLANDS',
    mauritania: 'MAURITANIA',
    mauritius: 'MAURITIUS',
    mexico: 'MEXICO',
    monaco: 'MONACO',
    mongolia: 'MONGOLIA',
    montserrat: 'MONTSERRAT',
    morocco: 'MOROCCO',
    mozambigue: 'MOZAMBIGUE',
    namibia: 'NAMIBIA',
    naura: 'NAURA',
    nepal: 'NEPAL',
    netherlands: 'NETHERLANDS',
    netherlands_antilles: 'NETHERLANDS ANTILLES',
    '(z)_new_zealand': 'NEW ZEALAND',
    new_zealand: 'NEW ZEALAND',
    nicaragua: 'NICARAGUA',
    niger: 'NIGER',
    '(z)_nigeria': 'NIGERIA',
    nigeria: 'NIGERIA',
    niue: 'NIUE',
    north_korea: 'NORTH KOREA',
    '(z)_northern_ireland': 'NORTHERN IRELAND',
    northern_ireland: 'NORTHERN IRELAND',
    norway: 'NORWAY',
    oman: 'OMAN',
    pakistan: 'PAKISTAN',
    panama: 'PANAMA',
    papua_new_guinea: 'PAPUA NEW GUINEA',
    paraguay: 'PARAGUAY',
    peru: 'PERU',
    '(z)_philippines': 'PHILIPPINES',
    philippines: 'PHILIPPINES',
    poland: 'POLAND',
    '(z)_portugal': 'PORTUGAL',
    portugal: 'PORTUGAL',
    qatar: 'QATAR',
    republic_of_south_africa: 'REPUBLIC OF SOUTH AFRICA',
    romania: 'ROMANIA',
    russia: 'RUSSIA',
    rwanda: 'RWANDA',
    samoa: 'SAMOA',
    sao_tome_and_principe: 'SAO TOME AND PRINCIPE',
    saudi_arabia: 'SAUDI ARABIA',
    scotland: 'SCOTLAND',
    senegal: 'SENEGAL',
    '(z)_seychelles': 'SEYCHELLES',
    seychelles: 'SEYCHELLES',
    sierra_leone: 'SIERRA LEONE',
    '(z)_singapore': 'SINGAPORE',
    singapore: 'SINGAPORE',
    slovakia: 'SLOVAKIA',
    solomon_islands: 'SOLOMON ISLANDS',
    somalia: 'SOMALIA',
    south_africa: 'SOUTH AFRICA',
    '(z)_spain': 'SPAIN',
    spain: 'SPAIN',
    sri_lanka: 'SRI LANKA',
    sudan: 'SUDAN',
    suriname: 'SURINAME',
    sweden: 'SWEDEN',
    switzerland: 'SWITZERLAND',
    'switzerland_(che)': 'SWITZERLAND (CHE)',
    syria: 'SYRIA',
    taiwan: 'TAIWAN',
    thailand: 'THAILAND',
    togo: 'TOGO',
    tonga: 'TONGA',
    trinidad_and_tobago: 'TRINIDAD AND TOBAGO',
    tunisia: 'TUNISIA',
    '(z)_turkey': 'TURKEY',
    turkey: 'TURKEY',
    uganda: 'UGANDA',
    ukraine: 'UKRAINE',
    united_arab_emirates: 'UNITED ARAB EMIRATES',
    '(z)_united_kingdom': 'UNITED KINGDOM',
    united_kingdom: 'UNITED KINGDOM',
    united_republic_of_cameroon: 'UNITED REPUBLIC OF CAMEROON',
    united_repulic_of_tanzania: 'UNITED REPULIC OF TANZANIA',
    '(z)_united_states_of_america': 'UNITED STATES OF AMERICA',
    united_states_of_america: 'UNITED STATES OF AMERICA',
    unknown_overseas_address: 'UNKNOWN OVERSEAS ADDRESS',
    uruguay: 'URUGUAY',
    'usa-special_delivery': 'USA-SPECIAL DELIVERY',
    vanuatu: 'VANUATU',
    vatican: 'VATICAN',
    venezuela: 'VENEZUELA',
    vietnam: 'VIETNAM',
    west_africa: 'WEST AFRICA',
    yemen: 'YEMEN',
    yugoslavia: 'YUGOSLAVIA',
    zaire: 'ZAIRE',
    zambia: 'ZAMBIA',
    zimbabwe: 'ZIMBABWE',
  },
};
