/* eslint-disable import/no-cycle */
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  makeStyles,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckRounded';
import InfoIcon from '@material-ui/icons/InfoRounded';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUserRounded';
import { format } from 'date-fns';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { UserReducerContext } from './duck';

const formatDate = (date: string) => format(new Date(date), 'yyyy-MM-dd HH:mm');

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    '& .react-table .table-row-actions': {
      visibility: 'visible',
    },
  },
  paper: {
    flex: 1,
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    boxShadow: '1px 2px 10px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#ffffff',
  },
  left: {
    height: '100%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
  },
  table: {
    '& .MuiTableCell-root': {
      borderBottom: 'unset',
    },
  },
  label: {
    fontSize: '1.4rem',
    color: '#707070',
    flex: 1,
  },
  value: {
    flex: 2,
    fontSize: '1.4rem',
    fontWeight: 'bold',
    color: '#707070',
    whiteSpace: 'pre-line',
  },
  tickerInput: {
    borderRadius: 4,
    border: 'solid 1px #d9d9d9',
    fontSize: '1.4rem',
    minWidth: 300,
  },
  buttonRemove: {
    width: 64,
    marginBottom: 4,
    textTransform: 'capitalize',
  },
  overlay: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'rgba(255,255,255,0.6)',
    borderRadius: 4,
    top: 0,
    left: 0,
    fontSize: '1.6rem',
    fontWeight: 'bold',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    color: 'black',
  },
  dialogMessage: {
    fontSize: '1.4rem',
    lineHeight: 1.43,
    textAlign: 'center',
    color: '#707070',
    whiteSpace: 'pre-line',
    margin: '24px 0',
  },
  bottomContainer: {
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    minWidth: 400,
  },
  buttonOK: {
    backgroundColor: '#fd101b',
    '&:hover': {
      backgroundColor: '#fd101b',
      opacity: 0.8,
    },
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
  },
  labelOK: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: ' #fff',
  },
}));

const TabUserProfile: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();

  const { t } = useTranslation('manage_user');
  const {
    state: { user, application },
  } = useContext(UserReducerContext);

  return (
    <Grid
      container
      direction="row"
      alignItems="stretch"
      spacing={4}
      className={classes.root}
    >
      <Grid item xs={12} md={6} className={classes.left}>
        <div className={classes.column}>
          <div className="section-container">
            <InfoIcon className="section-icon" />
            <span className="section-title f1">
              {t('manage_user:user_info')}
            </span>
          </div>
        </div>
        <Paper className={classes.paper}>
          <Table className={classes.table} aria-label="user infomation table">
            <TableBody>
              {[
                {
                  label: t('name'),
                  value:
                    application?.accountType === 'New'
                      ? application?.name
                      : user?.name,
                  pending: application?.name,
                },
                {
                  label: t('email'),
                  value:
                    application?.accountType === 'New'
                      ? application?.username
                      : user?.username,
                  pending: application?.username,
                },
                {
                  label: t('manage_company:phone_number'),
                  value:
                    application?.accountType === 'New'
                      ? application?.phoneNo && application?.areaCode
                        ? `+${application.areaCode}-${application.phoneNo}`
                        : ''
                      : user?.phoneNo && user?.areaCode
                      ? `+${user.areaCode}-${user.phoneNo}`
                      : '',
                  pending:
                    application?.phoneNo && application?.areaCode
                      ? `+${application.areaCode}-${application.phoneNo}`
                      : '',
                },
                {
                  label: t('manage_staff:department'),
                  value:
                    application?.accountType === 'New'
                      ? application?.dept
                      : user?.dept,
                  pending: application?.dept,
                  staffOnly: true,
                },
                {
                  label: t('status'),
                  value: user?.status
                    ? t(`manage_user:${user.status.toLowerCase()}`)
                    : '-',
                },
                {
                  label: t('user_type'),
                  value:
                    application?.accountType === 'New'
                      ? application?.role === 'ClientUser'
                        ? 'Client User'
                        : application?.role === 'ClientMaker'
                        ? 'Client Maker'
                        : application?.role === 'ClientChecker'
                        ? 'Client Checker'
                        : application?.role === 'TricorChecker'
                        ? 'Staff Checker'
                        : application?.role === 'TricorMaker'
                        ? 'Staff Maker'
                        : application?.role === 'TricorSuperAdmin'
                        ? 'Tricor Super Admin'
                        : '-'
                      : user?.role === 'ClientUser'
                      ? 'Client User'
                      : user?.role === 'ClientMaker'
                      ? 'Client Maker'
                      : user?.role === 'ClientChecker'
                      ? 'Client Checker'
                      : user?.role === 'TricorChecker'
                      ? 'Staff Checker'
                      : user?.role === 'TricorMaker'
                      ? 'Staff Maker'
                      : user?.role === 'TricorSuperAdmin'
                      ? 'Tricor Super Admin'
                      : '-',
                  pending:
                    application?.role === 'ClientUser'
                      ? 'Client User'
                      : application?.role === 'ClientMaker'
                      ? 'Client Maker'
                      : application?.role === 'ClientChecker'
                      ? 'Client Checker'
                      : application?.role === 'TricorChecker'
                      ? 'Staff Checker'
                      : application?.role === 'TricorMaker'
                      ? 'Staff Maker'
                      : application?.role === 'TricorSuperAdmin'
                      ? 'Tricor Super Admin'
                      : '-',
                },
                {
                  label: t('last_login'),
                  value: user?.lastLoginDate
                    ? formatDate(user.lastLoginDate)
                    : '-',
                },
                {
                  label: t('created_at'),
                  value:
                    application?.accountType === 'New'
                      ? formatDate(application.createdAt)
                      : user?.createdAt
                      ? formatDate(user.createdAt)
                      : '-',
                },
                {
                  label: t('requested_by'),
                  value: application?.requestedBy || user?.requestedBy,
                },
              ].map(({ label, value, pending, staffOnly }) =>
                staffOnly && user?.userType !== 'S' ? undefined : (
                  <TableRow
                    key={`row-${label}`}
                    style={{ verticalAlign: 'top' }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.label}
                      colSpan={1}
                    >
                      {label}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.value}
                      colSpan={2}
                      style={{
                        backgroundColor:
                          pending && value !== pending ? 'yellow' : undefined,
                      }}
                    >
                      {pending && value !== pending ? pending : value || '-'}
                    </TableCell>
                  </TableRow>
                ),
              )}
              {application?.approvedAt && (
                <TableRow style={{ verticalAlign: 'top' }}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.label}
                    colSpan={1}
                  >
                    Approval Date
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.value}
                    colSpan={2}
                  >
                    {formatDate(application.approvedAt)}
                  </TableCell>
                </TableRow>
              )}
              {application?.rejectedAt && (
                <TableRow style={{ verticalAlign: 'top' }}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.label}
                    colSpan={1}
                  >
                    Approval Date
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.value}
                    colSpan={2}
                  >
                    {formatDate(application.rejectedAt)}
                  </TableCell>
                </TableRow>
              )}
              {application?.rejectReason && (
                <TableRow style={{ verticalAlign: 'top' }}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.label}
                    colSpan={1}
                  >
                    Reject Reason
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.value}
                    colSpan={2}
                  >
                    {application.rejectReason}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      {application?.role?.startsWith('Tricor') && (
        <Grid item xs={12} md={6}>
          <div className="section-container">
            <VerifiedUserIcon className="section-icon" />
            <span className="section-title f1">
              {t('manage_user:access_rights')}
            </span>
          </div>
          <Paper className={classes.paper}>
            <Table className={classes.table} aria-label="user infomation table">
              <TableBody>
                {[
                  {
                    label: t('tabbar:entity_management'),
                    value:
                      application?.accountType === 'New'
                        ? application?.accessRights?.entityManagement
                        : user?.accessRights?.entityManagement,
                    pending: application?.accessRights?.entityManagement,
                  },
                  {
                    label: t('tabbar:register_of_members'),
                    value:
                      application?.accountType === 'New'
                        ? application?.accessRights?.registerOfMember
                        : user?.accessRights?.registerOfMember,
                    pending: application?.accessRights?.registerOfMember,
                  },
                  {
                    label: t('tabbar:ccass_holding'),
                    value:
                      application?.accountType === 'New'
                        ? application?.accessRights?.ccassHolding
                        : user?.accessRights?.ccassHolding,
                    pending: application?.accessRights?.ccassHolding,
                  },
                  {
                    label: t('tabbar:reports'),
                    value:
                      application?.accountType === 'New'
                        ? application?.accessRights?.reports
                        : user?.accessRights?.reports,
                    pending: application?.accessRights?.reports,
                  },
                  {
                    label: t('tabbar:shareholder_analytics'),
                    value:
                      application?.accountType === 'New'
                        ? application?.accessRights?.ownershipAnalysis
                        : user?.accessRights?.ownershipAnalysis,
                    pending: application?.accessRights?.ownershipAnalysis,
                  },
                  {
                    label: t('tabbar:market_intelligence'),
                    value:
                      application?.accountType === 'New'
                        ? application?.accessRights?.marketIntelligence
                        : user?.accessRights?.marketIntelligence,
                    pending: application?.accessRights?.marketIntelligence,
                  },
                  {
                    label: t('tabbar:industry_analyst_report'),
                    value:
                      application?.accountType === 'New'
                        ? application?.accessRights?.industryAnalysis
                        : user?.accessRights?.industryAnalysis,
                    pending: application?.accessRights?.industryAnalysis,
                  },
                  {
                    label: t('tabbar:eagm'),
                    value:
                      application?.accountType === 'New'
                        ? application?.accessRights?.eAGM
                        : user?.accessRights?.eAGM,
                    pending: application?.accessRights?.eAGM,
                  },
                  // {
                  //   label: t('tabbar:eproxy'),
                  //   value: user?.accessRights?.eProxy,
                  // },
                  {
                    label: t('tabbar:board_portal'),
                    value:
                      application?.accountType === 'New'
                        ? application?.accessRights?.boardPortal
                        : user?.accessRights?.boardPortal,
                    pending: application?.accessRights?.boardPortal,
                  },
                  {
                    label: t('tabbar:invoice_management'),
                    value:
                      application?.accountType === 'New'
                        ? application?.accessRights?.invoiceManagement
                        : user?.accessRights?.invoiceManagement,
                    pending: application?.accessRights?.invoiceManagement,
                  },
                  {
                    label: t('tabbar:thought_leadership'),
                    value:
                      application?.accountType === 'New'
                        ? application?.accessRights?.thoughtLeadership
                        : user?.accessRights?.thoughtLeadership,
                    pending: application?.accessRights?.thoughtLeadership,
                  },
                ].map(({ label, value, pending }) => (
                  <TableRow key={`row-${label}`}>
                    <TableCell component="td" className={classes.label}>
                      {label}
                    </TableCell>
                    <TableCell component="td" className={classes.value}>
                      <CheckIcon
                        htmlColor={pending ? '#fd101b' : 'rgba(0,0,0,0.3)'}
                        fontSize="large"
                        style={{
                          backgroundColor:
                            pending !== value ? 'yellow' : undefined,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default TabUserProfile;
