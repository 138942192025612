/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Theme,
  createStyles,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, SearchRounded } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { format, isValid, startOfYear, sub, subYears } from 'date-fns';
import { Field, Formik } from 'formik';
import {
  CheckboxProps,
  CheckboxWithLabel,
  fieldToCheckbox,
} from 'formik-material-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Column,
  Filters,
  SortingRule,
  useAsyncDebounce,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import './PaginationTable.css';

import { DialogContent, DialogTitle } from './Dialog';
import PaginationTableRow from './PaginationTableRow';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    pagination: {
      marginTop: 12,
      alignSelf: 'center',
      '& .MuiPaginationItem-root': {
        fontSize: '1rem',
        fontWeight: 'bold',
        borderRadius: 20,
        '&.Mui-selected': {
          backgroundColor: '#0909B7',
          color: '#fff',
        },
      },
    },
    sortOn: {
      color: 'red',
    },
    sortOff: {},
    placeholderContainer: {
      width: '100%',
      backgroundColor: '#fff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontStyle: 'italic',
      fontWeight: 'bold',
      borderRadius: 4,
    },
    progressContainer: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0.3)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      marginBottom: 16,
    },
    dateTo: {
      fontSize: '1.4rem',
      color: '#262626',
      margin: '0 8px',
      display: 'inline-block',
    },
    searchbar: {
      flex: 1,
      backgroundColor: '#fff',
      marginLeft: 8,
    },
    label: {
      color: '#262626',
      fontSize: '1.4rem',
      fontWeight: 'bold',
      marginBottom: 8,
    },
    checkboxGroup: {
      '& .MuiFormControlLabel-label': { fontSize: '1.2rem' },
      '& .MuiIconButton-colorSecondary:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
      '& .MuiCheckbox-colorSecondary.Mui-checked': {
        color: '#e84637',
      },
      '& .Mui-checked svg': {
        fill: '#e84637',
      },
    },
    buttonConfirm: {
      margin: '8px 0',
      borderRadius: 24,
      backgroundColor: '#fd101b',
      '&:hover': {
        backgroundColor: '#fd101b',
        opacity: 0.8,
      },
      '& .MuiButton-endIcon': {
        marginLeft: 4,
      },
    },
    labelConfirm: {
      textTransform: 'capitalize',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      color: ' #fff',
    },
    dateInput: {
      backgroundColor: '#fff',
      paddingTop: 4,
      '& input': {
        borderWidth: 0,
      },
      '& .MuiOutlinedInput-input': {
        padding: '6px !important',
      },
      '& .MuiIconButton-root': {
        padding: 0,
      },
      '& legend': {
        display: 'none',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(0px, -4px) scale(0.75)',
      },
      '& .Mui-error': {
        fontSize: 10,
      },
    },
  }),
);

export const GlobalFilter = ({
  globalFilter,
  setGlobalFilter,
  searchPlaceholder,
}: any) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((val) => {
    setGlobalFilter(val || undefined);
  }, 200);

  return (
    <TextField
      value={value || ''}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      className="search-bar"
      id="outlined-search"
      type="search"
      variant="outlined"
      placeholder={searchPlaceholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchRounded htmlColor="#b4b4b4" />
          </InputAdornment>
        ),
      }}
      classes={{ root: classes.searchbar }}
    />
  );
};

const CheckboxAll = (props: CheckboxProps) => {
  const { t } = useTranslation('common');
  const {
    form: { setFieldValue, values, setValues },
  } = props;

  React.useEffect(() => {
    if (values.all) {
      const notAllSelected = Object.keys(values).some(
        (field) => field !== 'all' && !values[field],
      );
      if (notAllSelected) setFieldValue('all', false);
    } else {
      const allSelected = !Object.keys(values).some(
        (field) => field !== 'all' && !values[field],
      );
      if (allSelected) setFieldValue('all', true);
    }
  }, [values]);

  const onChange = React.useCallback(
    (event: React.ChangeEvent<{}>, checked: boolean) => {
      const newValues = Object.keys(values).reduce(
        (acc, field) => ({ ...acc, [field]: checked }),
        {},
      );
      setValues(newValues);
    },
    [setFieldValue],
  );

  return (
    <FormControlLabel
      control={<Checkbox {...fieldToCheckbox(props)} onChange={onChange} />}
      label={t('all')}
    />
  );
};

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }: any, ref: any) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  },
);

interface Props<D extends object> {
  defaultPageSize?: number;
  columns: Array<Column<D>>;
  data: D[];
  fetchData?: (props: {
    pageSize: number;
    pageIndex: number;
    query?: string;
    sortBy?: SortingRule<D>;
    filters?: Filters<D>;
    startDate?: string;
    endDate?: string;
  }) => void;
  loading: boolean;
  pageCount?: number;
  disableSearch?: boolean;
  searchPlaceholder?: string;
  onClickRow?: (data: D) => void;
  renderHeaderLeft?: JSX.Element;
  renderHeaderRight?: JSX.Element;
  renderActions?: (row: D, selected?: boolean) => JSX.Element | null;
  manualControl?: boolean;
  status?: string;
  onChangeFilters?: (filters: Filters<D>) => void;
  currentId?: string | null;
  filterByDate?: boolean;
  placeholder?: string;
  onFieldsModalClose?: () => void;
  selectFieldOpen?: boolean;
  renderExpandDetail?: (row: D, hideDetail: () => void) => JSX.Element | null;
  showEmptyRows?: boolean;
  selectFieldBlacklist?: string[];
  onFieldsChange?: (values: string[]) => void;
  showIndex?: boolean;
  hidePaginationControl?: boolean;
  extraHeader?: JSX.Element;
  onSelectedFlatRowsChange?: (data: any) => void;
  allowSelection?: boolean;
}

const PaginationTable = <T extends object>({
  defaultPageSize,
  columns,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  disableSearch = false,
  searchPlaceholder,
  onClickRow,
  renderActions,
  manualControl = true,
  status,
  onChangeFilters,
  currentId,
  filterByDate = false,
  placeholder = '',
  onFieldsModalClose,
  selectFieldOpen = false,
  renderExpandDetail,
  selectFieldBlacklist = [],
  showIndex = false,
  renderHeaderLeft,
  renderHeaderRight,
  onFieldsChange,
  hidePaginationControl,
  extraHeader,
  allowSelection,
  onSelectedFlatRowsChange,
}: Props<T>): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const matched = useMediaQuery('(min-width:640px)');
  const [startDate, setStartDate] = React.useState<Date>(
    startOfYear(subYears(new Date(), 5)),
  );
  const [endDate, setEndDate] = React.useState<Date>(
    sub(new Date(), { days: 1 }),
  );
  const {
    allColumns,
    totalColumnsWidth,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    setGlobalFilter,
    setFilter,
    toggleHideColumn,
    selectedFlatRows,
    state: {
      pageIndex,
      pageSize,
      sortBy,
      globalFilter,
      filters,
      hiddenColumns,
      selectedRowIds,
    },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: defaultPageSize || 10 },
      pageCount: controlledPageCount,
      manualPagination: manualControl,
      manualSortBy: manualControl,
      manualGlobalFilter: manualControl,
      manualFilters: manualControl,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((vc: any) =>
        allowSelection
          ? [
              {
                id: 'selection',
                Header: ({ getToggleAllPageRowsSelectedProps }) => (
                  <div>
                    <IndeterminateCheckbox
                      {...getToggleAllPageRowsSelectedProps()}
                    />
                  </div>
                ),
                Cell: ({ row }: any) => (
                  <div
                    onClick={(e: any) => {
                      e.stopPropagation();
                      e.nativeEvent.stopImmediatePropagation();
                    }}
                  >
                    <IndeterminateCheckbox
                      {...row.getToggleRowSelectedProps()}
                    />
                  </div>
                ),
                maxWidth: 40,
              },
              ...vc,
            ]
          : vc,
      );
    },
    useFlexLayout,
  );

  const checkboxFields = React.useMemo(() => {
    const fieldColumns = allColumns.filter(
      (column) => !selectFieldBlacklist.includes(column.id),
    );
    const left = fieldColumns.slice(0, Math.ceil(fieldColumns.length / 2));
    const right = fieldColumns.slice(Math.ceil(fieldColumns.length / 2));
    const fields: Column<T>[] = [];
    left.forEach((column, index) => {
      if (right[index]) fields.push(right[index]);
      fields.push(column);
    });

    return fields;
  }, [allColumns]);

  React.useEffect(() => {
    if (onSelectedFlatRowsChange && selectedFlatRows) {
      onSelectedFlatRowsChange(selectedFlatRows);
    }
  }, [selectedFlatRows]);

  React.useEffect(() => {
    return () => {
      if (onSelectedFlatRowsChange) {
        onSelectedFlatRowsChange([]);
      }
    };
  }, []);

  React.useEffect(() => {
    if (status && typeof status === 'string') setFilter('status', status);
    else if (filters.find((item) => item.id === 'status'))
      setFilter('status', undefined);
  }, [status]);

  React.useEffect(() => {
    if (onChangeFilters) {
      onChangeFilters(filters);
    }
  }, [filters]);

  React.useEffect(() => {
    if (fetchData) {
      fetchData({
        pageIndex: pageIndex + 1,
        pageSize,
        query: globalFilter,
        sortBy: sortBy?.[0],
        filters,
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
      });
    }
  }, [
    fetchData,
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
    startDate,
    endDate,
  ]);

  React.useEffect(() => {
    if (manualControl) gotoPage(0);
  }, [globalFilter, sortBy, manualControl]);

  React.useEffect(() => {
    if (onFieldsChange) {
      onFieldsChange(
        allColumns
          .filter((column) => !hiddenColumns?.includes(column.id))
          .map((column) => column.id),
      );
    }
  }, [hiddenColumns]);

  const RowsComponent = page.map((row, i) => {
    prepareRow(row);

    let selected = false;
    if (currentId) {
      selected = (row.original as { id: string }).id === currentId;
    } else if (selectedFlatRows && selectedFlatRows.length > 0) {
      selected = selectedFlatRows.some(
        (item) =>
          (item.original as any).id === (row.original as { id: string }).id,
      );
    }

    return (
      <PaginationTableRow
        key={`tr ${i}`}
        onClickRow={
          onClickRow &&
          (() => {
            onClickRow(row.original);
          })
        }
        style={{
          ...row.getRowProps().style,
          cursor: onClickRow ? 'pointer' : 'unset',
        }}
        row={row}
        selected={selected}
        renderActions={renderActions}
        renderExpandDetail={renderExpandDetail}
        showIndex={showIndex}
        index={pageIndex * pageSize + i + 1}
      />
    );
  });

  return (
    <div className={classes.root}>
      <Grid container direction="row" spacing={1} style={{ marginBottom: 8 }}>
        <Grid
          item
          direction="row"
          xs={6}
          md={7}
          alignItems="center"
          style={{ display: 'flex' }}
        >
          {filterByDate && (
            <>
              <div className={classes.dateTo}>{t('common:from')}</div>
              <KeyboardDatePicker
                autoOk
                disableFuture
                variant="inline"
                format="dd/MM/yyyy"
                value={startDate}
                minDate={startOfYear(subYears(new Date(), 5))}
                minDateMessage={t('common:start_must_after_minimal_date')}
                InputAdornmentProps={{ position: 'end' }}
                inputVariant="outlined"
                invalidDateMessage={t('common:please_enter_valid_date')}
                onChange={(date, value) => {
                  if (!date || !value || value.includes('_')) return;

                  if (!isValid(date)) {
                    alert(t('common:please_enter_valid_date'));
                    return;
                  }

                  const start = date.getTime();
                  if (endDate.getTime() < start) {
                    alert(t('common:start_date_should_before_end_date'));
                  } else if (start > Date.now()) {
                    alert(t('common:enter_any_date_preceding_current_date'));
                  } else {
                    setStartDate(date);
                  }
                  // else if (
                  //   isBefore(start, new Date('Jan 01 2016 00:00:00 GMT+0800'))
                  // ) {
                  //   alert(t('common:start_must_after_2016'));
                  // }
                }}
                // @ts-ignore
                classes={{ root: classes.dateInput }}
              />
              <div className={classes.dateTo}>{t('common:to')}</div>
              <KeyboardDatePicker
                autoOk
                disableFuture
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                value={endDate}
                minDate={startDate}
                maxDate={sub(new Date(), { days: 1 })}
                invalidDateMessage={t('common:please_enter_valid_date')}
                maxDateMessage={t(
                  'common:enter_any_date_preceding_current_date',
                )}
                InputAdornmentProps={{ position: 'end' }}
                onChange={(date, value) => {
                  if (!date || !value || value.includes('_')) return;

                  if (!isValid(date)) {
                    alert(t('common:please_enter_valid_date'));
                    return;
                  }

                  const end = date.getTime();

                  if (startDate.getTime() > end) {
                    alert(t('common:end_date_should_after_start_date'));
                  } else {
                    setEndDate(date);
                  }
                }}
                // @ts-ignore
                classes={{ root: classes.dateInput }}
              />
            </>
          )}
          {renderHeaderLeft}
        </Grid>
        <Grid
          item
          xs={6}
          md={5}
          direction="row"
          alignItems="center"
          justify="flex-end"
          style={{ display: 'flex' }}
        >
          {renderHeaderRight}
          {!disableSearch && (
            <GlobalFilter
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              searchPlaceholder={searchPlaceholder}
            />
          )}
        </Grid>
      </Grid>
      {extraHeader}
      <div className={`tableWrap${page.length === 0 ? ' empty' : ''}`}>
        <div
          {...getTableProps()}
          className="react-table"
          style={{ ...getTableProps().style, minWidth: 240, overflowX: 'auto' }}
        >
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {showIndex && (
                <div
                  className="th"
                  style={{
                    ...headerGroup.headers?.[0]?.getHeaderProps().style,
                    width: 40,
                    flex: 'unset',
                  }}
                >
                  #
                </div>
              )}
              {headerGroup.headers.map((column, index) => {
                let thStyle = {
                  ...column.getHeaderProps().style,
                };

                if (column.canFilter) {
                  thStyle = {
                    ...thStyle,
                    overflowWrap: 'anywhere',
                  };
                }

                if (column.maxWidth && column.maxWidth < 9999) {
                  thStyle = {
                    ...thStyle,
                    maxWidth: column.maxWidth,
                  };
                }

                if (column.id === 'selection')
                  return (
                    <th
                      {...column.getHeaderProps()}
                      className="th"
                      style={thStyle}
                    >
                      {column.render('Header')}
                    </th>
                  );

                return (
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="th"
                    style={thStyle}
                  >
                    {typeof column.Header === 'string' ? (
                      <span>{t(column.Header)}</span>
                    ) : (
                      column.Header
                    )}
                    {!column.disableSortBy && (
                      <>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ArrowDropDown
                              className={classes.sortOn}
                              fontSize="large"
                            />
                          ) : (
                            <ArrowDropUp
                              className={classes.sortOn}
                              fontSize="large"
                            />
                          )
                        ) : (
                          <ArrowDropDown
                            className={classes.sortOff}
                            fontSize="large"
                          />
                        )}
                      </>
                    )}
                    {column.canFilter && !column.disableFilters ? (
                      <>
                        <div className="f1" />
                        {column.render('Filter')}
                      </>
                    ) : null}
                  </div>
                );
              })}
            </div>
          ))}
          <div {...getTableBodyProps()} className="tbody">
            {RowsComponent}
            {RowsComponent.length === 0 && (
              <div
                className={classes.placeholderContainer}
                style={{
                  height: 34 * pageSize,
                  minWidth: totalColumnsWidth,
                }}
              >
                {!loading && placeholder}
              </div>
            )}
          </div>
          {loading && (
            <div
              className={classes.progressContainer}
              style={{
                height: '100%',
                minHeight: 34 * pageSize,
                minWidth: totalColumnsWidth,
              }}
            >
              <CircularProgress />
            </div>
          )}
        </div>
      </div>
      {!hidePaginationControl && (
        <Pagination
          count={pageCount}
          defaultPage={1}
          page={pageIndex + 1}
          showFirstButton
          showLastButton
          onChange={(e, value) => gotoPage(value - 1)}
          size={matched ? 'large' : 'small'}
          className={classes.pagination}
        />
      )}
      <Dialog
        onClose={onFieldsModalClose}
        aria-labelledby="select-fields-dialog-title"
        open={selectFieldOpen}
      >
        <DialogTitle
          id="select-fields-dialog-title"
          onClose={onFieldsModalClose}
        >
          {t('common:select_fields')}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={columns.reduce<{ [key: string]: boolean }>(
              (acc, column) =>
                selectFieldBlacklist.includes(column.accessor as string)
                  ? acc
                  : {
                      ...acc,
                      [column.accessor as string]: !hiddenColumns?.includes(
                        column.accessor as string,
                      ),
                    },
              { all: true },
            )}
            onSubmit={(values, { setSubmitting }) => {
              Object.keys(values)
                .filter((key) => key !== 'all')
                .forEach((key) => {
                  toggleHideColumn(key, !values[key]);
                });
              setSubmitting(false);
              if (onFieldsModalClose) onFieldsModalClose();
            }}
          >
            {({ submitForm, isSubmitting }) => (
              <>
                <div className={classes.checkboxGroup}>
                  <div className={classes.label}>{t('common:fields')}</div>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={CheckboxAll}
                        type="checkbox"
                        name="all"
                      />
                    </Grid>
                    {checkboxFields.map((column) => (
                      <Grid item key={column.id} xs={12} sm={6}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name={column.id}
                          Label={{
                            label: column.Header && t(column.Header.toString()),
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    classes={{
                      root: classes.buttonConfirm,
                      label: classes.labelConfirm,
                    }}
                    onClick={submitForm}
                    disabled={isSubmitting}
                  >
                    {t('common:confirm')}
                  </Button>
                </div>
              </>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PaginationTable;
