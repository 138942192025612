import { Button, Theme, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Column } from 'react-table';
import AdminService from '../../../api/admin';
import { DialogContent, DialogTitle } from '../../../components/Dialog';
import PaginationTable from '../../../components/PaginationTable';
import { CompanyUser } from '../../../models/CompanyUser';
import { selectUserInfo } from '../../auth/duck/selector';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    minWidth: '80%',
  },
  message: {
    fontSize: '1.4rem',
    lineHeight: 1.43,
    textAlign: 'center',
    color: '#707070',
    whiteSpace: 'pre-line',
    margin: '24px 0',
  },
  bottomContainer: {
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonRemove: {
    backgroundColor: '#fd101b',
    marginRight: 48,
    '&:hover': {
      backgroundColor: '#fd101b',
      opacity: 0.8,
    },
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
  },
  labelRemove: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: ' #fff',
  },
  labelCancel: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: '#707070',
  },
  buttonReject: {
    backgroundColor: '#fff',
    color: '#0909B7',
    borderColor: '#0909B7',
    fontWeight: 600,
    textTransform: 'none',
    marginLeft: 12,
  },
  input: {
    width: '100%',
    minWidth: 300,
  },
  notes: {
    fontSize: 12,
    lineHeight: 1.17,
    color: '#838383',
    marginBottom: 7,
  },
}));

interface Props {
  disabled?: boolean;
  onSent: () => void;

  applicationIds?: number[];

  showNote?: boolean;

  onSendError: (e: any) => void;

  isCompanyApplication?: boolean;
}

const SendReminderDialog: React.FC<Props> = ({
  disabled,
  onSent,
  onSendError,
  applicationIds,
  isCompanyApplication,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('manage_applications');
  const [open, setOpen] = React.useState(false);
  const user = useSelector(selectUserInfo);

  const [selectedCheckers, setSelectedCheckers] = React.useState<any[]>([]);
  const [data, setData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, query, sortBy }) => {
      if (!open || !user) return;
      const fetchId = ++fetchIdRef.current;
      setLoading(true);
      AdminService.getUserAssignedChecker(
        user.id,
        pageSize,
        pageIndex,
        query,
        sortBy,
      ).then((res) => {
        if (fetchId === fetchIdRef.current) {
          setData(res.data);
          setPageCount(res.totalPage);
          setLoading(false);
        }
      });
    },
    [open, user],
  );

  const handleClickOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickSend = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (!applicationIds || applicationIds.length === 0) {
      return;
    }

    setLoading(true);

    AdminService.sendPendingApprovalEmails(
      applicationIds,
      selectedCheckers.map((item) => item.original.id),
      isCompanyApplication,
    )
      .then(() => {
        onSent();
      })
      .catch((e) => {
        setLoading(false);
        onSendError(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const COLUMNS = React.useMemo<Array<Column<CompanyUser>>>(
    () => [
      {
        Header: 'manage_applications:name_email',
        accessor: 'username',
        disableFilters: true,
        Cell: ({ row: { original } }: any) =>
          `${original.name || ''} (${original.username})`,
      },
    ],
    [t],
  );

  return (
    <>
      <Button
        disabled={loading || disabled}
        variant="outlined"
        className={classes.buttonReject}
        onClick={handleClickOpen}
      >
        {t('manage_applications:button_send_reminder')}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="send-reminder-dialog-title"
        open={open}
        onClick={(e) => {
          e.stopPropagation();
        }}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle id="send-reminder-dialog-title" onClose={handleClose}>
          {t('select_checkers')}
        </DialogTitle>
        <DialogContent>
          <PaginationTable
            disableSearch
            allowSelection
            key={count}
            defaultPageSize={10}
            columns={COLUMNS}
            data={data}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            onSelectedFlatRowsChange={(rows) => {
              setSelectedCheckers(rows);
            }}
          />
        </DialogContent>
        <div className={classes.bottomContainer}>
          <Button
            variant="contained"
            color="primary"
            classes={{
              root: classes.buttonRemove,
              label: classes.labelRemove,
            }}
            onClick={handleClickSend}
            disabled={loading || selectedCheckers.length === 0}
          >
            {t('button_send')}
          </Button>
          <Button
            variant="outlined"
            classes={{ label: classes.labelCancel }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleClose();
            }}
            disabled={loading}
          >
            {t('common:cancel')}
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default SendReminderDialog;
