/* eslint-disable react/jsx-props-no-spreading */
import { Button, makeStyles } from '@material-ui/core';
import GpsNotFixedRoundedIcon from '@material-ui/icons/GpsNotFixedRounded';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import AdminService from '../../../api/admin';
import PaginationTable from '../../../components/PaginationTable';
import { Feedback } from '../../../models/Feedback';
import { saveAsFile } from '../../../utils';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    '& tr': {
      cursor: 'pointer',
    },
  },
  buttonDownload: {
    backgroundColor: '#0909B7',
    fontSize: '1.2rem',
    color: '#fff',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#001A5B',
      opacity: 0.8,
    },
  },
}));

const COLUMNS: Array<Column<Feedback>> = [
  {
    Header: 'feedback:date',
    accessor: 'createdAt',
    disableFilters: true,
    maxWidth: 100,
    Cell: ({ value }: any) => format(new Date(value), 'yyyy-MM-dd'),
  },
  {
    Header: 'feedback:name',
    accessor: 'user',
    disableFilters: true,
    Cell: ({ value }: any) => value.name,
  },
  {
    Header: 'feedback:comments',
    accessor: 'comment',
    disableFilters: true,
  },
  {
    Header: 'feedback:rating',
    accessor: 'rating',
    disableFilters: true,
  },
];

const ManageFeedback: React.FC = () => {
  const classes = useStyles();

  const [feedbacks, setFeedbacks] = React.useState<Feedback[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const { t } = useTranslation();

  const downloadReport = () => {
    AdminService.getFeedbacksReport()
      .then((res) => {
        saveAsFile(new Blob([res.data]), 'report-feedback.xlsx');
      })
      .catch((e) => {
        alert('Fail to download file');
      });
  };

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, query, sortBy, filters }) => {
      const fetchId = ++fetchIdRef.current;

      setLoading(true);
      AdminService.getFeedbacks(
        pageSize,
        pageIndex,
        query,
        sortBy,
        filters?.[0]?.id,
        filters?.[0]?.value,
      ).then(({ data, totalPage }) => {
        if (fetchId === fetchIdRef.current) {
          setFeedbacks(data);
          setPageCount(totalPage);
          setLoading(false);
        }
      });
    },
    [],
  );

  return (
    <div className={classes.container}>
      <PaginationTable
        defaultPageSize={20}
        columns={COLUMNS}
        data={feedbacks}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        placeholder="You have no feedback"
        disableSearch
        renderHeaderLeft={
          <>
            <GpsNotFixedRoundedIcon className="section-icon" />
            <span className="section-title f1">
              {t('tabbar:manage_feedback')}
            </span>
          </>
        }
        renderHeaderRight={
          <Button
            variant="contained"
            className={classes.buttonDownload}
            onClick={downloadReport}
          >
            {t('common:download')}
          </Button>
        }
      />
    </div>
  );
};

export default ManageFeedback;
