import { Grid, Tab, Tabs, Theme, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import Person from '@material-ui/icons/PersonRounded';
import clsx from 'clsx';
import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AdminService from '../../../../api/admin';
import { entityManagement, registerOfMember } from '../../../../assets';
import { DialogContent, DialogTitle } from '../../../../components/Dialog';
import TabPanel, { a11yProps } from '../../../../components/TabPanel';
import { CreateCompanyUserValues } from '../../../../models/CreateCompanyUser';
import { selectIsClient } from '../../../auth/duck/selector';
import { selectLang } from '../../../settings/duck/selector';
import TabContactPersons from './TabContactPersons';
import TabEntityManagement from './TabEntityManagement';
import TabHKTicker from './TabHKTicker';
import TabRegisterOfMembers from './TabRegisterOfMembers';
import TabUserInfo from './TabUserInfo';

const useStyles = makeStyles((theme: Theme) => ({
  bottomContainer: {
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonSubmit: {
    backgroundColor: '#0909B7',
    marginRight: 48,
    '&:hover': {
      backgroundColor: '#001A5B',
      opacity: 0.8,
    },
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
  },
  buttonCancel: {
    backgroundColor: '#fff',
    borderColor: '#0909B7',
    borderRadius: 8,
  },
  labelSubmit: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: ' #fff',
  },
  labelCancel: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: '#0909B7',
  },

  tab: {
    textTransform: 'capitalize',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    backgroundColor: '#f3f3f3',
    border: '1px solid #e7e7e7',
    color: '#ababab',
    marginRight: 4,
    borderTop: '4px solid #e7e7e7',
  },
  tabSelected: {
    borderBottomWidth: 0,
    backgroundColor: '#fff',
    color: '#000',
    borderTop: '4px solid #0909B7',
  },
  tabBorder: {
    width: '100%',
    height: 1,
    position: 'absolute',
    background: '#e7e7e7',
    bottom: 0,
  },
  wrapper: {
    flexDirection: 'row',
    '& svg': {
      marginBottom: '0px !important',
      marginRight: 6,
      height: '1em',
      width: '1em',
      '& path': {
        fill: '#0909B7',
        opacity: 1,
      },
    },
  },
  indicator: {
    display: 'none',
  },
}));

const initialValues = {
  email: '',
  name: '',
  areaCode: '',
  phoneNo: '',
  entityManagement: true,
  registerOfMember: true,
  ccassHolding: true,
  reports: true,
  ownershipAnalysis: true,
  industryAnalysis: true,
  eAGM: true,
  eProxy: true,
  boardPortal: true,
  marketIntelligence: true,
  invoiceManagement: true,
  thoughtLeadership: true,
  romOids: [],
  emCodes: [],
  checkerIds: [],
  csCompanies: [],
  contactPoints: [],
};

interface Props {
  isUser?: boolean;
  onCreateSuccess: () => void;
}

const CreateUser: React.FC<Props> = ({ onCreateSuccess }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const isClient = useSelector(selectIsClient);

  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const lang = useSelector(selectLang);

  const TABS = React.useMemo(() => {
    if (isClient) {
      return [
        { label: 'manage_user:profile', icon: Person },
        { label: 'manage_user:hk_ticker', icon: registerOfMember },
        { label: 'manage_user:register_of_members', icon: registerOfMember },
        { label: 'manage_user:entity_management', icon: entityManagement },
      ];
    }

    return [
      { label: 'manage_user:profile', icon: Person },
      { label: 'manage_user:hk_ticker', icon: registerOfMember },
      { label: 'manage_user:register_of_members', icon: registerOfMember },
      { label: 'manage_user:entity_management', icon: entityManagement },
      { label: 'manage_user:contact_point', icon: entityManagement },
    ];
  }, [isClient]);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validate = (values: CreateCompanyUserValues) => {
    const errors: Partial<CreateCompanyUserValues> = {};
    if (!values.email) errors.email = t('error:required');
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
      errors.email = 'Invalid email address';

    if (!values.name) errors.name = t('error:required');

    if (values.phoneNo.length > 0) {
      if (values.phoneNo && !/^[0-9]*$/.test(values.phoneNo))
        errors.phoneNo = 'Invalid number';

      if (values.phoneNo && !values.areaCode)
        errors.areaCode = t('error:required');

      if (values.areaCode === '852' && values.phoneNo.length !== 8) {
        errors.phoneNo = t('manage_user:error_mobile_format');
      }
    }
    if (!values.role) {
      errors.role = t('error:required');
    }

    if (!values.email || !values.role || !values.name) {
      alert(
        'Please fill in all the required fields at Profile section for creating the user',
      );
    }

    return errors;
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="small"
        endIcon={<AddRoundedIcon />}
        className="button-create"
        onClick={handleClickOpen}
      >
        {t('common:create')}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="create-user-dialog-title"
        open={open}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="create-user-dialog-title" onClose={handleClose}>
          {t('manage_user:create_user')}
        </DialogTitle>
        <DialogContent>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValues}
            validate={validate}
            onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
              const {
                email,
                name,
                areaCode,
                phoneNo,
                role,
                romOids,
                emCodes,
                checkerIds,
                csCompanies,
                contactPoints,
                ...others
              } = values;

              AdminService.createCompanyUser({
                name,
                username: email,
                areaCode,
                phoneNo,
                role,
                accessRight: { ...others },
                romMappings: (romOids || []).map((romOid) => ({ romOid })),
                emMappings: (emCodes || []).map((fileCode) => ({ fileCode })),
                checkerMappings:
                  role === 'ClientMaker'
                    ? (checkerIds || []).map((userId) => ({ userId }))
                    : [],
                csCompanyMappings: (csCompanies || []).map((item) => ({
                  TickerNo: item.tickerNo,
                  TickerRegion: item.tickerRegion,
                })),
                contactPoints: (contactPoints || []).map((item) => ({
                  tricorId: item.staff.id,
                  isPrimary: item.isPrimary,
                })),
              })
                .then((res) => {
                  onCreateSuccess();
                  setSubmitting(false);
                  resetForm();
                  handleClose();
                  // history.push(`/admin/manage_user/${res.data.id}`);
                })
                .catch((e) => {
                  let errorMsgKey = 'message';
                  if (lang === 'zh') errorMsgKey = 'messageZH';
                  else if (lang === 'cn') errorMsgKey = 'messageCN';

                  alert(e.response.data[errorMsgKey]);
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            {({ submitForm, isSubmitting, setFieldValue, values }) => (
              <>
                <Grid container>
                  <Grid item xs={12}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="on"
                      aria-label="scrollable auto tabs example"
                      classes={{ indicator: classes.indicator }}
                    >
                      <div className={classes.tabBorder} />
                      {TABS.map((item, index) => (
                        <Tab
                          {...a11yProps(index)}
                          key={item.label}
                          icon={<item.icon htmlColor="#f50057" />}
                          value={index}
                          label={t(item.label)}
                          disabled={isSubmitting}
                          disableFocusRipple
                          disableTouchRipple
                          disableRipple
                          classes={{
                            root: clsx(classes.tab, {
                              [classes.tabSelected]: index === value,
                            }),
                            wrapper: classes.wrapper,
                          }}
                        />
                      ))}
                      {/* {values.role === 'ClientMaker' &&
                        TABS_MAKER.map((item, index) => (
                          <Tab
                            {...a11yProps(TABS.length + index)}
                            key={item.label}
                            icon={<item.icon />}
                            value={TABS.length}
                            label={t(item.label)}
                            disabled={isSubmitting}
                            disableFocusRipple
                            disableTouchRipple
                            disableRipple
                            classes={{
                              root: clsx(classes.tab, {
                                [classes.tabSelected]:
                                  TABS.length + index === value,
                              }),
                              wrapper: classes.wrapper,
                            }}
                          />
                        ))} */}
                    </Tabs>
                  </Grid>
                </Grid>
                <TabPanel value={value} index={0}>
                  <TabUserInfo />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <TabHKTicker
                    csCompanies={values.csCompanies || []}
                    onChange={(data: any[]) =>
                      setFieldValue('csCompanies', data)
                    }
                  />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <TabRegisterOfMembers
                    romOids={values.romOids || []}
                    onChange={(ids: string[]) => setFieldValue('romOids', ids)}
                  />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <TabEntityManagement
                    emCodes={values.emCodes || []}
                    onChange={(ids: string[]) => setFieldValue('emCodes', ids)}
                  />
                </TabPanel>
                {!isClient && (
                  <TabPanel value={value} index={4}>
                    <TabContactPersons
                      contactPoints={values.contactPoints || []}
                      onChange={(data: any[]) =>
                        setFieldValue('contactPoints', data)
                      }
                    />
                  </TabPanel>
                )}
                {/* {values.role === 'ClientMaker' && (
                  <TabPanel value={value} index={5}>
                    <TabClientChecker
                      checkerIds={values.checkerIds || []}
                      onChange={(ids: number[]) =>
                        setFieldValue('checkerIds', ids)
                      }
                    />
                  </TabPanel>
                )} */}
                <div className={classes.bottomContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    classes={{
                      root: classes.buttonSubmit,
                      label: classes.labelSubmit,
                    }}
                    onClick={submitForm}
                    disabled={isSubmitting}
                  >
                    {t('common:create')}
                  </Button>
                  <Button
                    variant="outlined"
                    classes={{
                      root: classes.buttonCancel,
                      label: classes.labelCancel,
                    }}
                    onClick={handleClose}
                    disabled={isSubmitting}
                  >
                    {t('common:cancel')}
                  </Button>
                </div>
              </>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateUser;
